<template>
  <feather-icon icon="PrinterIcon" svgClasses="ml-5 w-5 h-5 hover:text-primary stroke-current" @click="getBookLists()"/>
</template>

<script>
  import jsPDF from "jspdf";
  import 'jspdf-autotable'

  const fs = require('fs');
  import {font} from "./assets/THSarabunNew-normal";
  import {font_bold} from "./assets/THSarabunNew-bold";
  import moment from "moment";
  import BAHTTEXT from "./assets/BAHTTEXT";
  import {formatNumber} from "./assets/utils";

  export default {
    name: "BillReport",
    components: {
      jsPDF,
    },
    props: ['billConfig','imgBase64', 'org', 'imgLogoBase64', 'status', 'area'],
    data() {
      return {
        // 5 บรรทัด
        topLeft: {x: 45, y: 13},
        topCenter: {x: 140, y: 13},
        topRight: {x: 330, y: 13},
        bottomLeft: {x: 55, y: 70},
        bottomCenter: {x: 105, y: 58},
        bottomRight: {x: 340, y: 70},
        signature: {x: 0, y: 0},
        spacing: 102,
      }
    },
    methods: {
      maintainDialog() {
        this.$vs.dialog({
          type: 'alert',
          color: 'warning',
          title: `ขอปรับปรุงระบบ`,
          text: 'รายงานจะใช้งานได้หลังวันที่ 1/6/2565',
          acceptText: 'ปิด'
        })
      },
      formatTextDate(text){
        if(text !== '' && text !== undefined){
          const date = text.split('/');
          let year = ''
          if(date[2].toString().length === 11)
            year = parseInt(date[2]) + 2543;
          else
            year = parseInt(date[2]) + 543;
          return date[0] + '/' + date[1] + '/' + year;
        }else{
          return '-'
        }
      },
      async getBookLists() {
        // this.$vs.loading();
        let payload = {};
        payload.B01 = this.billConfig.value;
        if (this.area.value !== 'all') {
          payload.M02 = this.area.value;
        }
        await this.$store.dispatch("bookLists/fetchDebtDataListItems", payload).then((res) => {
          if (res.status === 200 && res.data.length !== 0) {
            const bookLists = res.data;
            this.generatePDF(bookLists);
            this.$vs.loading.close();
          } else {
            this.warningDialog();
            this.$vs.loading.close();
          }
        }).catch((error) => {
          console.log(error)
          this.warningDialog();
          this.$vs.loading.close();
        });
      },
      date(value) {
        let word = value.split(' ');
        return word[0];
      },
      checkData() {
        if (this.bookLists.length === 0) {
          this.warningDialog();
        } else {
          this.generatePDF();
        }
      },
      warningDialog(message) {
        this.$vs.dialog({
          type: 'alert',
          color: 'warning',
          title: `คำเตือน`,
          text: 'ไม่มีรายชื่อในรายงาน ป.32 รายรับประจำวัน ',
          acceptText: 'ปิด'
        })
      },
      generatePDF(bookLists) {
        // this.$vs.loading();
        let fileName = 'รายงานใบเสร็จค่าน้ำประปา';
        const yearTh = this.yearTh;
        const org = this.org;
        const baseUrl = 'http://188.166.177.25/';
        const logo = this.imgLogoBase64;
        const signature = this.imgBase64;
        // const doc = new jsPDF({ format: 'a3', orientation: 'landscape' });
        // const doc = new jsPDF('l', 'mm', [330, 210]);
        const doc = new jsPDF({
          orientation: "landscape",
          unit: "mm",
          format: [377.8, 289.4]
        });
        doc.addFileToVFS('THSarabunNew-normal.ttf', font);
        doc.addFileToVFS('THSarabunNew-bold.ttf', font_bold);
        doc.addFont('THSarabunNew-normal.ttf', 'THSarabunNew', 'normal');
        doc.addFont('THSarabunNew-bold.ttf', 'THSarabunNew', 'bold');
        doc.setFont('THSarabunNew', 'normal');
        const date = moment().format('DD/MM/YYYY')
        let dateSplit = date.split('/');
        let day = parseInt(dateSplit[0]) + 7;
        let year = parseInt(dateSplit[2]) + 543;
        let paymentDate = day + '/' + dateSplit[1] + '/' + year;
        let j = 0;
        doc.setFontSize(16);
        for (let i = 0; i < bookLists.length; i++) {
          let x = 0;
          let y = 0;
          const sum = parseFloat(bookLists[i].BA11) + parseFloat(bookLists[i].BA12) + parseFloat(bookLists[i].BA13)
          //TOP LEFT
          doc.text(bookLists[i].member_name, this.topLeft.x - 15, (this.topLeft.y + j * this.spacing) - 8);
          doc.text(bookLists[i].B03, this.topLeft.x + 5, (this.topLeft.y + j * this.spacing));
          doc.text(bookLists[i].CB04, this.topLeft.x + 5, (this.topLeft.y + j * this.spacing) + 10);
          doc.text(bookLists[i].B02, this.topLeft.x, (this.topLeft.y + j * this.spacing) + 9 * 2);
          doc.text(formatNumber(bookLists[i].BA12), this.topLeft.x - 10, (this.topLeft.y + j * this.spacing) + 9 * 3);
          doc.text(formatNumber(bookLists[i].BA13), this.topLeft.x + 30, (this.topLeft.y + j * this.spacing) + 9 * 3);
          doc.text(bookLists[i].B20.toString(), this.topLeft.x - 22, (this.topLeft.y + j * this.spacing) + 9 * 4);
          doc.text(bookLists[i].B18.toString(), this.topLeft.x + 8, (this.topLeft.y + j * this.spacing) + 9 * 4);
          doc.text(bookLists[i].B21.toString(), this.topLeft.x + 35, (this.topLeft.y + j * this.spacing) + 9 * 4);
          doc.text("ค่าขยะฯ", this.topLeft.x + 20, (this.topLeft.y + j * this.spacing) + 9 * 5);
          doc.text(formatNumber(bookLists[i].BA15), this.topLeft.x + 25, (this.topLeft.y + j * this.spacing) + 9 * 6);
          if(logo !== '' && logo !== null) {
            doc.addImage(logo, 'PNG',  this.topLeft.x + 40,(this.topLeft.y + j * this.spacing) - 13, 20, 20);
          }
          // BOTTOM LEFT
          doc.text(sum.toString(), this.bottomLeft.x - 30, (this.bottomLeft.y + j * this.spacing) - 3);
          if (signature !== '' && signature !== null) {
            doc.addImage(signature, 'PNG', this.bottomLeft.x, (this.bottomLeft.y + j * this.spacing), 20, 10);
          }
          //TOP CENTER
          doc.text(bookLists[i].CB04, this.topCenter.x + 15, (this.topCenter.y + j * this.spacing) );
          doc.text(bookLists[i].B03, this.topCenter.x + 93, (this.topCenter.y + j * this.spacing));
          doc.text(this.getTaxId(org.tax_id), this.topCenter.x + 75, (this.topCenter.y + j * this.spacing) - 9);
          doc.text(bookLists[i].member_meter_no, this.topCenter.x + 135, (this.topCenter.y + j * this.spacing));
          doc.text(bookLists[i].B02, this.topCenter.x + 105, (this.topCenter.y + j * this.spacing) + 10);
          doc.text(bookLists[i].member_name, this.topCenter.x - 10, (this.topCenter.y + j * this.spacing) + 12);
          doc.text(bookLists[i].member_address, this.topCenter.x - 10, (this.topCenter.y + j * this.spacing) + 22);
          if(logo !== '' && logo !== null) {
            doc.addImage(logo, 'PNG',  this.topCenter.x + 155,(this.topCenter.y + j * this.spacing) - 15, 20, 20);
          }
          //BOTTOM CENTER
          doc.text(bookLists[i].B20.toString(), this.bottomCenter.x + 10, (this.bottomCenter.y + j * this.spacing), {align: 'center'});
          doc.text(bookLists[i].B18.toString(), this.bottomCenter.x + 38, (this.bottomCenter.y + j * this.spacing), {align: 'center'});
          doc.text(this.formatTextDate(bookLists[i].B17.toString()), this.bottomCenter.x + 10, (this.bottomCenter.y + j * this.spacing) + 10, {align: 'center'});
          doc.text(this.formatTextDate(bookLists[i].B19.toString()), this.bottomCenter.x + 37, (this.bottomCenter.y + j * this.spacing) + 10, {align: 'center'});
          doc.text(BAHTTEXT(sum.toString()), this.bottomCenter.x + 65, (this.bottomCenter.y + j * this.spacing) + 10);
          doc.text(org.Org01, this.bottomCenter.x + 65, (this.bottomCenter.y + j * this.spacing) + 18);
          doc.text(org.Org03, this.bottomCenter.x + 65, (this.bottomCenter.y + j * this.spacing) + 22);
          doc.text(bookLists[i].B21.toString(), this.bottomCenter.x + 28 * 2 + 5, (this.bottomCenter.y + j * this.spacing), {align: 'center'});
          doc.text(formatNumber(bookLists[i].BA11.toString()), this.bottomCenter.x + 28 * 3 + 5, (this.bottomCenter.y + j * this.spacing), {align: 'center'});
          doc.text(formatNumber(bookLists[i].BA12.toString()), this.bottomCenter.x + 28 * 4, (this.bottomCenter.y + j * this.spacing), {align: 'center'});
          doc.text("ค่าขยะฯ", this.bottomCenter.x + 28 * 5, (this.bottomCenter.y + j * this.spacing) - 16, {align: 'center'});
          doc.text(formatNumber(bookLists[i].BA15.toString()), this.bottomCenter.x + (28 * 5) + 30, (this.bottomCenter.y + j * this.spacing) - 16, {align: 'center'});
          doc.text(formatNumber(bookLists[i].BA13.toString()), this.bottomCenter.x + 28 * 5, (this.bottomCenter.y + j * this.spacing), {align: 'center'});
          doc.text(sum.toString(), this.bottomCenter.x + 28 * 6, (this.bottomCenter.y + j * this.spacing), {align: 'center'});
          if(bookLists[i].B24 !== undefined)
            doc.text(bookLists[i].B24.toString(), this.bottomCenter.x + 28 * 6, (this.bottomCenter.y + j * this.spacing) + 27, {align: 'center'});
          if (signature !== '' && signature !== null) {
            doc.addImage(signature, 'PNG', this.bottomCenter.x + 12, (this.bottomCenter.y + j * this.spacing) + 10, 20, 10);
          }
          //TOP RIGHT
          doc.text(bookLists[i].B03, this.topRight.x + 25, (this.topRight.y + j * this.spacing));
          doc.text(bookLists[i].CB04, this.topRight.x + 20, (this.topRight.y + j * this.spacing) + 8);
          doc.text(bookLists[i].B02, this.topRight.x + 20, (this.topRight.y + j * this.spacing) + 8 * 2);
          doc.text(formatNumber(bookLists[i].BA12), this.topRight.x, (this.topRight.y + j * this.spacing) + 8 * 3);
          doc.text(formatNumber(bookLists[i].BA13), this.topRight.x + 47, (this.topRight.y + j * this.spacing) + 8 * 3);
          doc.text(bookLists[i].member_name, this.topRight.x, (this.topRight.y + j * this.spacing) + 8 * 4);
          //BOTTOM RIGHT
          doc.text(sum.toString(), this.bottomRight.x - 15, (this.bottomRight.y + j * this.spacing));
          doc.text("ค่าขยะฯ", this.bottomRight.x + 20, (this.bottomRight.y + j * this.spacing) - 15);
          doc.text(formatNumber(bookLists[i].BA15.toString()), this.bottomRight.x + 25, (this.bottomRight.y + j * this.spacing));
          j = j + 1;
          if ((i + 1) % 3 === 0 && i !== 1) {
            if (i + 1 === bookLists.length) break;
            doc.addPage();
            //doc.addImage(dataImage, 'JPEG',  0, 0, 210, 297);
            j = 0;
          }

        }
        // doc.setProperties({
        //   title: `report${new Date().toLocaleString()}`
        // });
        // doc.output("dataurlnewwindow");
        doc.save('ใบเสร็จ.pdf');
      },
      getTaxId(tax_id) {
        if(tax_id.toString().length === 13){
          return tax_id;
        }else{
          return tax_id.substring(0, 13);
        }

      }
    }
  }
</script>
