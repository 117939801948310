<template>
  <feather-icon icon="FileTextIcon" svgClasses="ml-5 w-5 h-5 hover:text-primary stroke-current" @click="getBookLists()"/>
</template>

<script>
  import moment from "moment";
  import moduleBookList from "@/store/booklist/moduleBookList.js";
  import {formatNumber2Digit, formatNumber} from "./assets/utils";
  import * as XLSX from "xlsx";

  export default {
    props: ['billConfig', 'area', 'org', 'status'],
    components: {
    },
    name: "P17PrapaReport",
    data() {
      return {
        thmonth: ['', 'มกราคม', 'กุมภาพันธ์', 'มีนาคม',
          'เมษายน', 'พฤษภาคม', 'มิถุนายน', 'กรกฎาคม', 'สิงหาคม', 'กันยายน',
          'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'],
      }
    },
    methods: {
      currentThaiDate() {
        const date = moment().format('DD/MM/YYYY');
        const dateSplit = date.split('/');
        const year = parseInt(dateSplit[2]) + 543;
        return dateSplit[0] + '/' + dateSplit[1] + '/' + year;
      },
      warningDialog() {
        this.$vs.dialog({
          type: 'alert',
          color: 'warning',
          title: `คำเตือน`,
          text: 'ไม่มีรายชื่อในรายงาน ป.17 ค่าน้ำ',
          acceptText: 'ปิด'
        })
      },
      async getBookLists() {
        this.$vs.loading();
        let payload = {};
        payload.B01 = this.billConfig.value;
        if (this.status !== undefined) {
          payload.B10 = this.status;
        }
        await this.$store.dispatch("bookLists/fetchDebtDataListItems", payload).then((res) => {
          if (res.status === 200 && res.data.length !== 0) {
            const booklists = res.data;
            this.generatePDF(booklists);
            this.$vs.loading.close();
          } else {
            this.warningDialog();
            this.$vs.loading.close();
          }
        }).catch((error) => {
          this.warningDialog();
          this.$vs.loading.close();
        });
      },
      generatePDF(bookLists) {
        const area = this.area;
        let sumUnit = 0;
        let sumPrapa = 0;
        let sumService = 0;
        let sumVat = 0;
        let sum = 0;
        let array = [];
        let sum_payment_footer = 0;
        for (let i = 0; i < bookLists.length; i++) {
          const data = bookLists[i];
          let BA11 = 0;
          let BA12 = 0;
          let BA13 = 0;
          let BA15 = 0;
          let sumBA = 0;
          if (data.BA11 !== undefined && data.BA11 !== null && data.BA11 !== '' && data.BA11 !== 'NaN') {
            BA11 = parseFloat(data.BA11);
            sumPrapa += parseFloat(data.BA11);
          }
          if (data.BA12 !== undefined && data.BA12 !== null && data.BA12 !== '' && data.BA12 !== 'NaN') {
            BA12 = parseFloat(data.BA12);
            sumService += parseFloat(data.BA12);
          }
          if (data.BA13 !== undefined && data.BA13 !== null && data.BA13 !== '' && data.BA13 !== 'NaN') {
            BA13 = parseFloat(data.BA13);
            sumVat += parseFloat(data.BA13);
          }
          if (data.BA15 !== undefined && data.BA15 !== null && data.BA15 !== '' && data.BA15 !== 'NaN') {
            BA15 = parseFloat(data.BA15);
          }
          if (data.B21 !== undefined && data.B21 !== null && data.B21 !== '' && data.B21 !== 'NaN') {
            sumUnit += parseInt(data.B21);
          }
          sumBA = BA11 + BA12 + BA13;
          sum += sumBA;
          let dateNow = moment(data.B24).format('DD/MM/YYYY');
          let formatDate = '';
          let day;
          let month;
          let yearth;
          if (dateNow === 'Invalid date') {
            let dateArr = data.B24.split(' ');
            let dateArr0 = dateArr[0].split('/');
            yearth = parseInt(dateArr0[2]) + 543;
            day = parseInt(dateArr0[0]);
            month = this.thmonth[parseInt(dateArr0[1])];
          } else {
            let dateArr0 = dateNow.split('/');
            yearth = parseInt(dateArr0[2]) + 543;
            day = parseInt(dateArr0[0])
            month = this.thmonth[parseInt(dateArr0[1])];
          }

          let status_bill = 'ค้างจ่าย';
          let sum_payment = 0;
          if (data.B10 === '1') {
            status_bill = 'จ่ายแล้ว';
            sum_payment = sumBA;
            formatDate = day + ' ' + month + ' ' + yearth;
          }
          sum_payment_footer += sum_payment;
          let B02 = ''
          if (data.B02 !== '')
            B02 = data.B02
          array.push({
            'ลำดับที่': i +1,
            'รหัส': data.B03,
            'ชื่อสกุลสมาชิกที่ใช้น้ำ' : data.member_name,
            'เลขที่ใบเสร็จ': B02,
            'สถานะบิลรอบที่จ่าย': status_bill,
            'สูตรค่าน้ำ': data.member_rate,
            'จดครั้งแรก': data.B18,
            'จดครั้งหลัง': data.B20,
            'จำนวนหน่วย': data.B21,
            'ค่าน้ำ': formatNumber2Digit(BA11),
            'ค่าบริการ': formatNumber2Digit(BA12),
            'ภาษี': formatNumber2Digit(BA13),
            'ค่าขยะ': formatNumber2Digit(BA15),
            'รวมเงิน': formatNumber(sumBA),
            'วันที่ชำระ': formatDate,
            'จำนวนเงินที่ชำระ': formatNumber(sum_payment)
          });
        }
        let fileName = `ป.17ค่าน้ำ-` + moment().format('DD-MM-YYYY') + '.xlsx';
        let ws = XLSX.utils.json_to_sheet(array);
        let wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, fileName);
        XLSX.writeFile(wb, fileName);
      }
    },
    created() {
      if (!moduleBookList.isRegistered) {
        this.$store.registerModule('bookLists', moduleBookList);
        moduleBookList.isRegistered = true;
      }
    }
  }
</script>
