import moment from "moment";

export default function FORMATDATE(val, format) {
  'use strict'
  // const date = moment(text).format('DD/MM/YYYY');
  // const dateSplit = date.split('/');
  // const year = parseInt(dateSplit[2]) + 543;
  // return dateSplit[0] + '/' + dateSplit[1] + '/' + year;
  const date = moment(val).locale("th");
  date.add(543, "years");
  return date.format(format);
}
