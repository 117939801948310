<template>
  <feather-icon icon="PrinterIcon" svgClasses="ml-5 w-5 h-5 hover:text-primary stroke-current" @click="getBookLists()"/>
</template>

<script>
  import 'jspdf-autotable'
  import jsPDF from "jspdf";
  import {font} from "./assets/THSarabunNew-normal";
  import {font_bold} from "./assets/THSarabunNew-bold";
  import moment from "moment";
  import moduleBookList from "@/store/booklist/moduleBookList.js";
  import {formatNumber2Digit, formatNumber} from "./assets/utils";
  import BAHTTEXT from "./assets/BAHTTEXT";

  export default {
    props: ['billConfig', 'area', 'org', 'status', 'imgBase64', 'imgLogoBase64'],
    components: {
      jsPDF,
    },
    name: "P17PrapaReport",
    data() {
      return {
        thmonth: ['', 'มกราคม', 'กุมภาพันธ์', 'มีนาคม',
          'เมษายน', 'พฤษภาคม', 'มิถุนายน', 'กรกฎาคม', 'สิงหาคม', 'กันยายน',
          'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'],
      }
    },
    methods: {
      maintainDialog() {
        this.$vs.dialog({
          type: 'alert',
          color: 'warning',
          title: `ขอปรับปรุงระบบ`,
          text: 'รายงานจะใช้งานได้หลังวันที่ 1/6/2565',
          acceptText: 'ปิด'
        })
      },
      currentThaiDate() {
        const date = moment().format('DD/MM/YYYY');
        const dateSplit = date.split('/');
        const year = parseInt(dateSplit[2]) + 543;
        return dateSplit[0] + '/' + dateSplit[1] + '/' + year;
      },
      warningDialog() {
        this.$vs.dialog({
          type: 'alert',
          color: 'warning',
          title: `คำเตือน`,
          text: 'ไม่มีรายชื่อในรายงาน ป.17 ค่าน้ำ',
          acceptText: 'ปิด'
        })
      },
      formatTextDate(text){
        if(text !== '' && text !== undefined){
          const date = text.split('/');
          let year = ''
          if(date[2].toString().length === 11)
            year = parseInt(date[2]) + 2543;
          else
            year = parseInt(date[2]) + 543;
          return date[0] + '/' + date[1] + '/' + year;
        }else{
          return '-'
        }
      },
      async getBookLists() {
        this.$vs.loading();
        let payload = {};
        payload.B01 = this.billConfig.value;
        if (this.area.value !== 'all') {
          payload.M02 = this.area.value;
        }
        if (this.status !== undefined) {
          payload.B10 = this.status;
        }
        await this.$store.dispatch("bookLists/fetchDebtDataListItems", payload).then((res) => {
          if (res.status === 200 && res.data.length !== 0) {
            const booklists = res.data;
            this.generatePDF(booklists);
            this.$vs.loading.close();
          } else {
            this.warningDialog();
            this.$vs.loading.close();
          }
        }).catch((error) => {
          console.log(error)
          this.warningDialog();
          this.$vs.loading.close();
        });
      },
      getTaxId(tax_id) {
        if(tax_id.toString().length === 13){
          return tax_id;
        }else{
          return tax_id.substring(0, 13);
        }
      },
      generatePDF(bookLists) {
        const doc = new jsPDF({
          orientation: "landscape",
          unit: "mm",
          format: [210, 140]
        });
        const org = this.org;
        const district = org.Org01;
        const address = org.Org03;
        const month = bookLists[0].CB04;
        const id_bill = bookLists[0].B01;
        const area = this.area;
        const date = this.currentThaiDate()
        const tax_id = this.getTaxId(org.tax_id)
        const signature = this.imgBase64;
        const logo = this.imgLogoBase64;
        let sumUnit = 0;
        let sumPrapa = 0;
        let sumService = 0;
        let sumVat = 0;
        let sumWaterWaste = 0;
        let sumGarbage = 0;
        let sum = 0;
        let array = [];
        let sum_payment_footer = 0;
        var totalPagesExp = '{total_pages_count_string}';
        doc.addFileToVFS('THSarabunNew-normal.ttf', font);
        doc.addFileToVFS('THSarabunNew-bold.ttf', font_bold);
        doc.addFont('THSarabunNew-normal.ttf', 'THSarabunNew', 'normal');
        doc.addFont('THSarabunNew-bold.ttf', 'THSarabunNew', 'bold');
        for (let i = 0; i < bookLists.length; i++) {
          const data = bookLists[i];
          const bookList = bookLists[i];
          if(bookList.member_phone === undefined){
            bookList.member_phone = '-'
          }
          let BA11 = 0;
          let BA12 = 0;
          let BA13 = 0;
          let BA14 = 0;
          let BA15 = 0;
          let sumBA = 0;
          if (data.BA11 !== undefined && data.BA11 !== null && data.BA11 !== '' && data.BA11 !== 'NaN') {
            BA11 = parseFloat(data.BA11);
            sumPrapa += parseFloat(data.BA11);
          }
          if (data.BA12 !== undefined && data.BA12 !== null && data.BA12 !== '' && data.BA12 !== 'NaN') {
            BA12 = parseFloat(data.BA12);
            sumService += parseFloat(data.BA12);
          }
          if (data.BA13 !== undefined && data.BA13 !== null && data.BA13 !== '' && data.BA13 !== 'NaN') {
            BA13 = parseFloat(data.BA13);
            sumVat += parseFloat(data.BA13);
          }
          if (data.BA14 !== undefined && data.BA14 !== null && data.BA14 !== '' && data.BA14 !== 'NaN') {
            BA14 = parseFloat(data.BA14);
            sumWaterWaste += parseFloat(data.BA14);
          }
          if (data.BA15 !== undefined && data.BA15 !== null && data.BA15 !== '' && data.BA15 !== 'NaN') {
            BA15 = parseFloat(data.BA15);
            sumGarbage += parseFloat(data.BA15);
          }
          if (data.B21 !== undefined && data.B21 !== null && data.B21 !== '' && data.B21 !== 'NaN') {
            sumUnit += parseInt(data.B21);
          }
          sumBA = BA11 + BA12 + BA13 + BA14 + BA15

          let status_bill = 'ค้างจ่าย';
          let sum_payment = 0;
          if (data.B10 === '1') {
            status_bill = 'จ่ายแล้ว';
            sum_payment = sumBA;
          }
          sum_payment_footer += sum_payment;
          let B22 = ''
          if(data.B22 !== '')
            B22 = data.B22
          array.push([
            data.B20,
            data.B18,
            data.B21,
            formatNumber2Digit(BA11),
            formatNumber2Digit(BA12),
            formatNumber2Digit(BA14),
            formatNumber2Digit(BA15),
            formatNumber2Digit(BA13),
            formatNumber(sumBA)
          ]);
          array.push([
            this.formatTextDate(data.B19),
            this.formatTextDate(data.B17),
            {content: BAHTTEXT(sumBA) , colSpan: 6, styles: { halign: 'center'}},
          ]);
          doc.autoTable({
            showHead: 'everyPage',
            showFoot: 'everyPage',
            styles: {font: 'THSarabunNew', fontSize: 14, textColor: 0},
            margin: {left: 10, top: 70, right: 10},
            headStyles: {fillColor: [255, 255, 255], textColor: 0, lineColor: 180, lineWidth: 0.1 , fontSize: 12 , halign: 'center'},
            theme: 'grid',
            columnStyles: {
              0: {cellWidth: 25, halign: 'center'},
              1: {cellWidth: 25, halign: 'center'},
              2: {cellWidth: 20, halign: 'center'},
              3: {cellWidth: 20, halign: 'center'},
              4: {cellWidth: 20, halign: 'center'},
              5: {cellWidth: 20, halign: 'center'},
              6: {cellWidth: 15, halign: 'center'},
              7: {cellWidth: 15, halign: 'center'},
              8: {cellWidth: 25, halign: 'center'},
            },
            head: [
              [
                'จดครั้งหลัง',
                'จดครั้งก่อน',
                'หน่วยที่ใช้',
                'ค่าน้ำ',
                'ค่ารักษามาตร',
                'บำบัดฯ',
                'ค่าขยะ',
                'ภาษี',
                'รวมเป็นเงินทั้งสิ้น',
              ]
            ],
            body: array,
            didDrawPage: function (data) {
              // Header
              doc.setFont('THSarabunNew', 'bold');
              doc.setFontSize(16);
              doc.text('ใบแจ้งหนี้ค่าน้ำประปา(ไม่ใช่ใบเสร็จรับเงิน)', doc.internal.pageSize.getWidth() / 2, 15, {align: 'center'});
              doc.text(org.Org01, doc.internal.pageSize.getWidth() / 2, 22, {align: 'center'});
              doc.setFontSize(14);
              doc.text(address, doc.internal.pageSize.getWidth() / 2, 29, {align: 'center'});
              doc.text('เลขประจำตัวผู้เสียภาษี '+tax_id, doc.internal.pageSize.getWidth() / 2, 36, {align: 'center'});
              doc.text('ค่าน้ำประจำเดือน     '+bookList.CB04, 10, 43);
              doc.text('ได้รับเงินจาก     '+bookList.member_name, 10, 50);
              doc.text('ที่อยู่     '+bookList.member_address_no+ ' '+bookList.member_address, 10, 57);
              doc.text('เลขประจำมาตร     '+bookList.member_meter_no, 10, 64);
              doc.text('เบอร์โทร     '+bookList.member_phone, 90, 64);
              doc.text('ใบแจ้งหนี้เลขที่     '+bookList.B22, 150, 43);
              doc.text('เลขที่ผู้ใช้น้ำ     '+bookList.B03, 150, 50);
              if(signature !== '' && signature !== null) {
                doc.addImage(signature, 'PNG',  150,100, 20, 10);
              }
              doc.text('หัวหน้าหน่วยงานคลัง', 142, 120);
              if(logo !== '' && logo !== null) {
                doc.addImage(logo, 'PNG',  45,10, 20, 20);
              }
              doc.setFont('THSarabunNew', 'normal');
            },
          });
          array = []
          if (i + 1 === bookLists.length) break;
          doc.addPage();
        }
        if (typeof doc.putTotalPages === 'function') {
          doc.putTotalPages(totalPagesExp);
        }
        doc.save(`ป.17ค่าน้ำ-` + moment().format('DD-MM-YYYY') + '.pdf');
        // doc.setProperties({
        //   title: `report${new Date().toLocaleString()}`
        // });
        // doc.output("dataurlnewwindow");
      }
    },
    created() {
      if (!moduleBookList.isRegistered) {
        this.$store.registerModule('bookLists', moduleBookList);
        moduleBookList.isRegistered = true;
      }
    }
  }
</script>
