<template>
  <feather-icon icon="PrinterIcon" svgClasses="ml-5 w-5 h-5 hover:text-primary stroke-current" @click="getBookLists()"/>
</template>

<script>
  import jsPDF from "jspdf";
  import 'jspdf-autotable'

  const fs = require('fs');
  import {font} from "./assets/THSarabunNew-normal";
  import {font_bold} from "./assets/THSarabunNew-bold";
  import moment from "moment";
  import {formatNumber, formatNumber2Digit} from "./assets/utils";
  import BAHTTEXT from "./assets/BAHTTEXT";
  import moduleBookList from "@/store/booklist/moduleBookList.js";

  export default {
    M04: "BillReport",
    components: {
      jsPDF,
    },
    props: ['billConfig','imgBase64', 'org', 'imgLogoBase64', 'status', 'area'],
    data() {
      return {
        // 5 บรรทัด
        left: {x: 15, y: 12},
        center: {x: 87, y: 12},
        right: {x: 160, y: 12},
        signature: {x: 0, y: 0},
        spacing: 94,
      }
    },
    methods: {
      maintainDialog() {
        this.$vs.dialog({
          type: 'alert',
          color: 'warning',
          title: `ขอปรับปรุงระบบ`,
          text: 'รายงานจะใช้งานได้หลังวันที่ 1/6/2565',
          acceptText: 'ปิด'
        })
      },
      formatTextDate(text){
        if(text !== '' && text !== undefined){
          const date = text.split('/');
          let year = ''
          if(date[2].toString().length === 11)
            year = parseInt(date[2]) + 2543;
          else
            year = parseInt(date[2]) + 543;
          return date[0] + '/' + date[1] + '/' + year;
        }else{
          return '-'
        }
      },
      async getBookLists() {
        // this.$vs.loading();
        let payload = {};
        payload.B01 = this.billConfig.value;
        if (this.area.value !== 'all') {
          payload.M02 = this.area.value;
        }
        await this.$store.dispatch("bookLists/fetchDebtDataListItems", payload).then((res) => {
          if (res.status === 200 && res.data.length !== 0) {
            const bookLists = res.data;
            this.printPdf(bookLists);
            this.$vs.loading.close();
          } else {
            this.warningDialog();
            this.$vs.loading.close();
          }
        }).catch((error) => {
          console.log(error)
          this.warningDialog();
          this.$vs.loading.close();
        });
      },
      async printPdf(bookLists) {
        let book = bookLists;
        if(book === null || book === undefined || book === '') {
          this.warningDialog();
        } else {
          this.config = await this.$store.state.config.item;
          this.report(book);
        }
      },
      date(value) {
        let word = value.split(' ');
        return word[0];
      },
      checkData() {
        if (this.bookLists.length === 0) {
          this.warningDialog();
        } else {
          this.generatePDF();
        }
      },
      warningDialog() {
        this.$vs.dialog({
          type: 'alert',
          color: 'warning',
          title: `คำเตือน`,
          text: 'ไม่มีข้อมูลบันทึกการชำระ',
          acceptText: 'ปิด'
        })
      },
      report(bookLists) {
        const signature = this.imgBase64;
        const logo = this.imgLogoBase64;
        const org = this.org;
        const doc = new jsPDF({
          orientation: "portrait",
          unit: "mm",
          format: [279.4, 210]
        });
        doc.addFileToVFS('THSarabunNew-normal.ttf', font);
        doc.addFileToVFS('THSarabunNew-bold.ttf', font_bold);
        doc.addFont('THSarabunNew-normal.ttf', 'THSarabunNew', 'normal');
        doc.addFont('THSarabunNew-bold.ttf', 'THSarabunNew', 'bold');
        doc.setFont('THSarabunNew', 'normal');
        doc.setFontSize(14);
        let j = 0;
        for (let i = 0; i < bookLists.length; i++) {
          let BA11 = 0;
          let BA12 = 0;
          let BA13 = 0;
          let BA15 = 0;
          if (bookLists[i].BA11 !== undefined && bookLists[i].BA11 !== null && bookLists[i].BA11 !== '') {
            BA11 = parseFloat(bookLists[i].BA11);
          }
          if (bookLists[i].BA12 !== undefined && bookLists[i].BA12 !== null && bookLists[i].BA12 !== '') {
            BA12 = parseFloat(bookLists[i].BA12);
          }
          if (bookLists[i].BA13 !== undefined && bookLists[i].BA13 !== null && bookLists[i].BA13 !== '' && bookLists[i].BA13 !== '0') {
            BA13 = parseFloat(((BA11 + BA12) * 0.07));
          }
          if (bookLists[i].BA15 !== undefined && bookLists[i].BA15 !== null && bookLists[i].BA15 !== '') {
            BA15 = parseFloat(bookLists[i].BA15);
          }
          let sum = BA11 + BA12 + BA13 + BA15;
          sum = parseFloat(sum);
          let line = j;
          doc.setFontSize(18);
          if(bookLists[i].B10 === '1'){
            doc.text('ชำระเงินแล้ว', this.center.x, (this.center.y + j * this.spacing) - line - 5);
          }
          //LEFT
          doc.setFontSize(14);
          doc.text(org.Org01, this.left.x, (this.left.y + j * this.spacing) - line);
          doc.setFontSize(12);
          doc.text(org.Org03, this.left.x, (this.left.y + j * this.spacing) - line + 5);
          doc.text(bookLists[i].B02, this.left.x, (this.left.y + j * this.spacing) - line + 5 * 2);
          doc.text(bookLists[i].CB04, this.left.x, (this.left.y + j * this.spacing) - line + 5 * 3);
          doc.text(bookLists[i].B03, this.left.x + 40, (this.left.y + j * this.spacing) - line + 5 * 3);
          doc.text(bookLists[i].member_name.toString(), this.left.x - 13, (this.left.y + j * this.spacing) - line + 5 * 4.3);
          doc.text(bookLists[i].member_meter_no.toString(), this.left.x + 40, (this.left.y + j * this.spacing) - line + 5 * 4);
          doc.text(bookLists[i].member_address.toString(), this.left.x - 10, (this.left.y + j * this.spacing) - line + 5 * 5);
          doc.text(bookLists[i].member_road.toString(), this.left.x - 10, (this.left.y + j * this.spacing) - line + 5 * 6 - 2);
          doc.text(bookLists[i].member_area.toString(), this.left.x + 40, (this.left.y + j * this.spacing) - line + 5 * 5.5);
          doc.setFontSize(10);
          doc.text(this.formatTextDate(bookLists[i].B17.toString()), this.left.x, (this.left.y + j * this.spacing) - line + 5 * 8);
          doc.text(this.formatTextDate(bookLists[i].B19.toString()), this.left.x, (this.left.y + j * this.spacing) - line + 5 * 9);
          doc.setFontSize(12);
          doc.text(formatNumber(bookLists[i].BA12), this.left.x + 5, (this.left.y + j * this.spacing) - line + 5 * 10.2);
          doc.text('ภาษีฯ               '+formatNumber(bookLists[i].BA13) +'         บาท', this.left.x - 15, (this.left.y + j * this.spacing) - line + 5 * 11.4);
          doc.text('ค่าขยะฯ           '+bookLists[i].BA15.toString() +'         บาท', this.left.x - 15, (this.left.y + j * this.spacing) - line + 5 * 12.4);
          doc.text(BAHTTEXT(sum), this.left.x - 12, (this.left.y + j * this.spacing) - line + 5 * 13.4);
          doc.text(bookLists[i].B18.toString(), this.left.x + 15, (this.left.y + j * this.spacing) - line + 5 * 8);
          doc.text(bookLists[i].B20.toString(), this.left.x + 15, (this.left.y + j * this.spacing) - line + 5 * 9);
          doc.text(bookLists[i].B21.toString(), this.left.x + 30, (this.left.y + j * this.spacing) - line + 5 * 8.5);
          doc.text(formatNumber(bookLists[i].BA11), this.left.x + 40, (this.left.y + j * this.spacing) - line + 5 * 8.5);
          doc.setFontSize(16);
          doc.text(formatNumber(sum.toString()), this.left.x + 35, (this.left.y + j * this.spacing) - line + 5 * 12);
          if(signature !== '' && signature !== null) {
            doc.addImage(signature, 'PNG',  this.left.x + 15,(this.left.y + j * this.spacing) - line + 5 * 13 + 2, 20, 8);
          }
          if(logo !== '' && logo !== null) {
            doc.addImage(logo, 'PNG',  this.left.x + 49,(this.left.y - 6 + j * this.spacing), 15, 15);
          }
          if(logo !== '' && logo !== null) {
            doc.addImage(logo, 'PNG',  this.center.x + 51,(this.left.y - 6 + j * this.spacing), 15, 15);
          }
          //CENTER
          doc.setFontSize(14);
          doc.text(org.Org01, this.center.x -10, (this.center.y + j * this.spacing) - line);
          doc.setFontSize(12);
          doc.text(org.Org03, this.center.x -10, (this.center.y + j * this.spacing) - line + 5);
          doc.text(bookLists[i].B02, this.center.x, (this.center.y + j * this.spacing) - line + 5 * 2);
          doc.text(bookLists[i].CB04, this.center.x, (this.center.y + j * this.spacing) - line + 5 * 3);
          doc.text(bookLists[i].B03, this.center.x + 40, (this.center.y + j * this.spacing) - line + 5 * 3);
          doc.text(bookLists[i].member_name.toString(), this.center.x - 13, (this.center.y + j * this.spacing) - line + 5 * 4.3);
          doc.text(bookLists[i].member_meter_no.toString(), this.center.x + 40, (this.center.y + j * this.spacing) - line + 5 * 4.5);
          doc.text(bookLists[i].member_address.toString(), this.center.x - 10, (this.center.y + j * this.spacing) - line + 5 * 5);
          doc.text(bookLists[i].member_road.toString(), this.center.x - 10, (this.center.y + j * this.spacing) - line + 5 * 6 - 2);
          doc.text(bookLists[i].member_area.toString(), this.center.x + 40, (this.center.y + j * this.spacing) - line + 5 * 5.5);
          doc.setFontSize(10);
          doc.text(this.formatTextDate(bookLists[i].B17.toString()), this.center.x, (this.center.y + j * this.spacing) - line + 5 * 8);
          doc.text(this.formatTextDate(bookLists[i].B19.toString()), this.center.x, (this.center.y + j * this.spacing) - line + 5 * 9);
          doc.setFontSize(12);
          doc.text(formatNumber(bookLists[i].BA12), this.center.x, (this.center.y + j * this.spacing) - line + 5 * 10.2);
          doc.text('ภาษีฯ          '+formatNumber(bookLists[i].BA13), this.center.x - 15, (this.center.y + j * this.spacing) - line + 5 * 11.4);
          doc.text('ค่าขยะฯ       '+bookLists[i].BA15.toString() +'         บาท', this.center.x - 15, (this.center.y + j * this.spacing) - line + 5 * 12.4);
          doc.text(BAHTTEXT(sum), this.center.x - 15, (this.center.y + j * this.spacing) - line + 5 * 13.4);
          doc.text(bookLists[i].B18.toString(), this.center.x + 15, (this.center.y + j * this.spacing) - line + 5 * 8);
          doc.text(bookLists[i].B20.toString(), this.center.x + 15, (this.center.y + j * this.spacing) - line + 5 * 9);
          doc.text(bookLists[i].B21.toString(), this.center.x + 30, (this.center.y + j * this.spacing) - line + 5 * 8.5);
          doc.text(formatNumber(bookLists[i].BA11), this.center.x + 45, (this.center.y + j * this.spacing) - line + 5 * 8.5);
          doc.setFontSize(16);
          doc.text(formatNumber(sum.toString()), this.center.x + 40, (this.center.y + j * this.spacing) - line + 5 * 12);
          if(signature !== '' && signature !== null) {
            doc.addImage(signature, 'PNG',  this.center.x - 10, (this.center.y + j * this.spacing) - line + 5 * 13 + 2, 20, 8);
          }
          doc.setFontSize(12);
          doc.text(bookLists[i].B09.toString(),  this.center.x + 35, (this.center.y + j * this.spacing) - line + 5 * 14.5);
          if(bookLists[i].B24 !== undefined){
            doc.text('วันที่รับเงิน '+bookLists[i].B24.toString(),  this.center.x + 30, (this.center.y + j * this.spacing)  - line + 5 * 13 + 2);
            doc.text('วันที่ '+bookLists[i].B24.toString(), this.left.x + 15, (this.left.y + j * this.spacing) - line + 5 * 2);
          }

          //RIGHT
          doc.setFontSize(12);
          doc.text(bookLists[i].CB04.toString(), this.right.x, (this.right.y + j * this.spacing) - line + 5 * 2);
          doc.text(bookLists[i].B03.toString(), this.right.x, (this.right.y + j * this.spacing) - line + 5 * 3.3);
          doc.text(bookLists[i].B22.toString(), this.right.x, (this.right.y + j * this.spacing) - line + 5 * 4.5);
          doc.text(bookLists[i].member_name.toString(), this.right.x - 10, (this.right.y + j * this.spacing) - line + 5 * 6);
          doc.text(bookLists[i].member_address.toString(), this.right.x - 10, (this.right.y + j * this.spacing) - line + 5 * 7);
          doc.text(bookLists[i].member_road.toString(), this.right.x - 10, (this.right.y + j * this.spacing) - line + 5 * 8);
          doc.setFontSize(16);
          doc.text(formatNumber(sum.toString()), this.right.x, (this.right.y + j * this.spacing) - line + 5 * 12.5);
          doc.setFontSize(12);
          // doc.text(bookLists[i].B24.toString(), this.right.x, (this.right.y + j * this.spacing) - line + 5 * 15.5);
          j = j + 1;
          if ((i + 1) % 3 === 0) {
            if (i + 1 === bookLists.length) break;
            doc.addPage();
            j = 0;
          }
        }
        // doc.setProperties({
        //   title: `รายงานใบเสร็จค่าน้ำประปา-${new Date().toLocaleString()}`
        // });
        // doc.output("dataurlnewwindow");
        doc.save(`ใบเสร็จ-${new Date().toLocaleString()}.pdf`);
      },
    }
    ,
    created() {
      if (!moduleBookList.isRegistered) {
        this.$store.registerModule('bookLists', moduleBookList);
        moduleBookList.isRegistered = true;
      }
    }
  }
</script>
