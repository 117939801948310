<template>
  <feather-icon icon="PrinterIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" @click="getBookLists()"/>
</template>

<script>
  import 'jspdf-autotable'
  import jsPDF from "jspdf";
  import {font} from "./assets/THSarabunNew-normal";
  import {font_bold} from "./assets/THSarabunNew-bold";
  import moment from "moment";
  import moduleBookList from "@/store/booklist/moduleBookList.js";

  export default {
    props: ['billConfig', 'area', 'org'],
    components: {
      jsPDF,
    },
    name: "RealUserReport",
    data() {
      return {
        title: 'บัญชีรายตัวลูกหนี้ ประเภทค่ากำจัดขยะมูลฝอย',
      }
    },
    methods: {
      maintainDialog() {
        this.$vs.dialog({
          type: 'alert',
          color: 'warning',
          title: `ขอปรับปรุงระบบ`,
          text: 'รายงานจะใช้งานได้หลังวันที่ 1/6/2565',
          acceptText: 'ปิด'
        })
      },
      currentThaiDate() {
        const date = moment().format('DD/MM/YYYY');
        const dateSplit = date.split('/');
        const year = parseInt(dateSplit[2]) + 543;
        return dateSplit[0] + '/' + dateSplit[1] + '/' + year;
      },
      warningDialog() {
        this.$vs.dialog({
          type: 'alert',
          color: 'warning',
          title: `คำเตือน`,
          text: 'ไม่มีรายชื่อในรายงาน ป.17 ค่ากำจัดขยะมูลฝอย',
          acceptText: 'ปิด'
        })
      },
      async getBookLists() {
        this.$vs.loading();
        let payload;
        if (this.area.value === 'all') {
          payload = {
            B01: this.billConfig.value
          };
        } else {
          payload = {
            M02: this.area.value,
            B01: this.billConfig.value
          };
        }
        await this.$store.dispatch("bookLists/fetchDebtDataListItems", payload).then((res) => {
          if (res.status === 200 && res.data.length !== 0) {
            const bookLists = res.data;
            this.generatePDF(bookLists);
            this.$vs.loading.close();
          } else {
            this.warningDialog();
            this.$vs.loading.close();
          }
        }).catch((error) => {
          this.warningDialog();
          this.$vs.loading.close();
        });
      },
      generatePDF(bookLists) {
        const doc = new jsPDF({format: [279, 210], unit: 'mm', orientation: 'portrait',});
        const title = this.title;
        const area = this.area;
        const orgData = this.org;
        const date = this.currentThaiDate()
        let sumBA15 = 0;
        let sumPaid = 0;
        let array = [];
        let count = 0
        for (let i = 0; i < bookLists.length; i++) {
          let BA15 = 0;
          if (bookLists[i].BA15 !== undefined && bookLists[i].BA15 !== null && bookLists[i].BA15 !== '' && bookLists[i].BA15 !== 'NaN') {
            BA15 = parseFloat(bookLists[i].BA15);
            sumBA15 += parseFloat(bookLists[i].BA15);
          }
          if (BA15 > 0) {
            let paid = 0.0
            let paidStatus = 'ค้างจ่าย'
            if (bookLists[i].B10 === '1') {
              paid = BA15
              paidStatus = 'จ่ายแล้ว';
              sumPaid += parseFloat(bookLists[i].BA15);
            }
            array.push([
              count + 1,
              bookLists[i].B03,
              bookLists[i].member_name,
              bookLists[i].B22,
              paidStatus,
              parseFloat(BA15).toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2}),
              parseFloat(paid).toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2}),
            ]);
            count ++
          }
        }
        array.push([
          {content: ' ', colSpan: 5, styles: {lineWidth: 0}},
          sumBA15.toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2}),
          sumPaid
        ]);
        var totalPagesExp = '{total_pages_count_string}';
        doc.addFileToVFS('THSarabunNew-normal.ttf', font);
        doc.addFileToVFS('THSarabunNew-bold.ttf', font_bold);
        doc.addFont('THSarabunNew-normal.ttf', 'THSarabunNew', 'normal');
        doc.addFont('THSarabunNew-bold.ttf', 'THSarabunNew', 'bold');
        doc.autoTable({
          showHead: 'everyPage',
          showFoot: 'everyPage',
          styles: {font: 'THSarabunNew', textColor: 0, fontSize: 13},
          margin: {left: 8, top: 33, right: 8},
          headStyles: {fillColor: [255, 255, 255], textColor: 0, lineColor: 180, lineWidth: 0.1},
          theme: 'grid',
          head: [
            [
              {content: area.label, colSpan: 5},
              {content: 'ค่าขยะ', styles: {halign: 'center'}},
              {content: 'การชำระ', styles: {halign: 'center'}}
            ],
            ['ลำดับที่', 'รหัส', 'ชื่อสกุลสมาชิกที่ใช้น้ำ', 'เลขที่บิล', 'สถานะบิลรอบที่จ่าย', 'ตั้งเก็บ', 'ชำระเงิน']
          ],
          body: array,
          didDrawPage: function (data) {
            // Header
            doc.setFont('THSarabunNew', 'bold');
            doc.setFontSize(16);
            doc.text(orgData.Org01, doc.internal.pageSize.getWidth() / 2, 10, {align: 'center'});
            doc.text(orgData.Org03, doc.internal.pageSize.getWidth() / 2, 16, {align: 'center'});
            doc.setFontSize(14);
            doc.text(title, doc.internal.pageSize.getWidth() / 2, 23, {align: 'center'});
            doc.text('ประจำเดือน ' + bookLists[0].CB04 + ' (' + bookLists[0].B01 + ')', doc.internal.pageSize.getWidth() / 2, 28, {align: 'center'});
            doc.setFont('THSarabunNew', 'normal');
            //footer
            var str = 'Page ' + doc.internal.getNumberOfPages();
            if (typeof doc.putTotalPages === 'function') {
              str = str + ' of ' + totalPagesExp
            }
            doc.setFontSize(12);
            var pageSize = doc.internal.pageSize;
            var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
            doc.text('วันที่พิมพ์ ' + date, 8, pageHeight - 8,)
            // doc.text('หน้าที่พิมพ์ ' + str, 200, pageHeight - 8, {align: 'right'});
          },
        });
        if (typeof doc.putTotalPages === 'function') {
          doc.putTotalPages(totalPagesExp);
        }
        doc.save('ป.17ค่ากำจัดขยะมูลฝอย-' + moment().format('DD-MM-YYYY') + '.pdf');
      }
    }
  }
</script>
