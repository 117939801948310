<template>
  <feather-icon icon="PrinterIcon" svgClasses="ml-5 w-5 h-5 hover:text-primary stroke-current" @click="getBookLists()"/>
</template>

<script>
  import 'jspdf-autotable'
  import jsPDF from "jspdf";
  import {font} from "./assets/THSarabunNew-normal";
  import {font_bold} from "./assets/THSarabunNew-bold";
  import moment from "moment";
  import moduleBookList from "@/store/booklist/moduleBookList.js";
  import {formatNumber2Digit, formatNumber} from "./assets/utils";
  export default {
    props: ['billConfig', 'area', 'org', 'status'],
    components: {
      jsPDF,
    },
    name: "P17PrapaReport",
    data() {
      return {
        thmonth: ['', 'มกราคม', 'กุมภาพันธ์', 'มีนาคม',
          'เมษายน', 'พฤษภาคม', 'มิถุนายน', 'กรกฎาคม', 'สิงหาคม', 'กันยายน',
          'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'],
      }
    },
    methods: {
      maintainDialog() {
        this.$vs.dialog({
          type: 'alert',
          color: 'warning',
          title: `ขอปรับปรุงระบบ`,
          text: 'รายงานจะใช้งานได้หลังวันที่ 1/6/2565',
          acceptText: 'ปิด'
        })
      },
      currentThaiDate() {
        const date = moment().format('DD/MM/YYYY');
        const dateSplit = date.split('/');
        const year = parseInt(dateSplit[2]) + 543;
        return dateSplit[0] + '/' + dateSplit[1] + '/' + year;
      },
      warningDialog() {
        this.$vs.dialog({
          type: 'alert',
          color: 'warning',
          title: `คำเตือน`,
          text: 'ไม่มีรายชื่อในรายงาน ป.17 ค่าน้ำ',
          acceptText: 'ปิด'
        })
      },
      async getBookLists() {
        this.$vs.loading();
        let payload = {};
        if (this.status !== undefined) {
          payload.B10 = this.status;
        }
        await this.$store.dispatch("bookLists/fetchDebtByAreaDataListItems", payload).then((res) => {
          if (res.status === 200 && res.data.length !== 0) {
            const booklists = res.data;
            this.generatePDF(booklists);
            this.$vs.loading.close();
          } else {
            this.warningDialog();
            this.$vs.loading.close();
          }
        }).catch((error) => {
          this.warningDialog();
          this.$vs.loading.close();
        });
      },
      formatDate(text) {
        try {
          const date = moment(text).format('DD/MM/YYYY');
          const dateSplit = date.split('/');
          const year = parseInt(dateSplit[2]) + 543;
          return dateSplit[0] + '/' + dateSplit[1] + '/' + year;
        }catch (e) {
          return text
        }
      },
      groupBy(xs, key) {
        return xs.reduce(function(rv, x) {
          (rv[x[key]] = rv[x[key]] || []).push(x);
          return rv;
        }, {});
      },
      generatePDF(bookLists) {
        const doc = new jsPDF({
          orientation: "portrait",
          unit: "mm",
          format: [279.4, 210]
        });
        const org = this.org;
        const district = org.Org01;
        const address = org.Org03;
        const month = bookLists[0].CB04;
        const id_bill = bookLists[0].B01;
        const date = this.currentThaiDate()
        const area = this.area;
        let sumBA = 0;
        let sum = 0;
        let array = [];
        let sum_payment_footer = 0;
        let index = 0;
        bookLists.sort((a, b) => parseInt(a['member_area']) - parseInt(b['member_area']));
        let group = this.groupBy(bookLists, 'member_area');
        let count = 0
        Object.keys(group).forEach(function (key) {
          array = []
          let sumUnit = 0;
          let sumPrapa = 0;
          let sumService = 0;
          let sumVat = 0;
          let sumPrapaVat = 0;
          array.push([
            'เส้นทาง',
            key,
            '',
            '',
            '',
            '',
            '',
            '',
            '',
          ]);
          for (let i = 0; i < group[key].length; i++) {
            const item = group[key][i];
            item.list.forEach(function (data) {
              index += 1;
              let BA11 = 0;
              let BA12 = 0;
              let BA13 = 0;
              let sumBA = 0;
              if (data.BA11 !== undefined && data.BA11 !== null && data.BA11 !== '' && data.BA11 !== 'NaN') {
                BA11 = parseFloat(data.BA11);
                sumPrapa += parseFloat(data.BA11);
                sumBA += parseFloat(data.BA11);
              }
              if (data.BA12 !== undefined && data.BA12 !== null && data.BA12 !== '' && data.BA12 !== 'NaN') {
                BA12 = parseFloat(data.BA12);
                sumService += parseFloat(data.BA12);
                sumBA += parseFloat(data.BA12);
              }
              if (data.BA13 !== undefined && data.BA13 !== null && data.BA13 !== '' && data.BA13 !== 'NaN') {
                BA13 = parseFloat(data.BA13);
                sumBA += parseFloat(data.BA13);
                sumVat += parseFloat(data.BA13);
                sumPrapaVat += parseFloat(data.BA11) + parseFloat(data.BA13)
              }
              if (data.B21 !== undefined && data.B21 !== null && data.B21 !== '' && data.B21 !== 'NaN') {
                sumUnit += parseInt(data.B21);
              }

              sum += sumBA;
              let sum_payment = 0;
              if (data.B10 === '1') {
                sum_payment = sumBA;
              }
              sum_payment_footer += sum_payment;
              let B22 = ''
              if(data.B22 !== '')
                B22 = data.B22
              array.push([
                index,
                data.B03,
                item.member_name,
                data.CB04,
                B22,
                formatNumber2Digit(BA11),
                formatNumber2Digit(BA12),
                formatNumber2Digit(BA13),
                formatNumber2Digit(sumBA)
              ]);
            })
          }
          array.push([
            'รวมทั้งสิ้น',
            '',
            '',
            '',
            '',
            formatNumber2Digit(sumPrapa),
            formatNumber2Digit(sumService),
            formatNumber2Digit(sumVat),
            formatNumber2Digit(sum),
          ]);
          array.push([
            'ค่าน้ำ+ภาษี',
            '',
            '',
            '',
            '',
            formatNumber(sumPrapaVat),
          ]);

          array.push([
            'ค่าบริการ',
            '',
            '',
            '',
            '',
            formatNumber(sumService),
          ]);
          doc.addFileToVFS('THSarabunNew-normal.ttf', font);
          doc.addFileToVFS('THSarabunNew-bold.ttf', font_bold);
          doc.addFont('THSarabunNew-normal.ttf', 'THSarabunNew', 'normal');
          doc.addFont('THSarabunNew-bold.ttf', 'THSarabunNew', 'bold');
          doc.autoTable({
            showHead: 'everyPage',
            showFoot: 'everyPage',
            styles: {font: 'THSarabunNew', fontSize: 11, textColor: 0},
            margin: {left: 16, top: 35, right: 5},
            theme: 'plain',
            head: [
              [
                'ลำดับที่',
                'รหัส',
                'ชื่อสกุลสมาชิกที่ใช้น้ำ',
                'ประจำเดือน',
                'เลขที่ใบแจ้งหนี้',
                'ค่าน้ำ',
                'ค่าบริการ',
                'ภาษี',
                'รวมเงิน'
              ]
            ],
            body: array,
            didDrawPage: function (data) {
              doc.setFont('THSarabunNew', 'bold');
              doc.setFontSize(16);
              doc.text(district, doc.internal.pageSize.getWidth() / 2, 10, {align: 'center'});
              doc.text(address, doc.internal.pageSize.getWidth() / 2, 16, {align: 'center'});
              doc.setFontSize(14);
              doc.text('บัญชีรายตัวลูกหนี้ ประเภทใช้มาตรวัดน้ำ', doc.internal.pageSize.getWidth() / 2, 25, {align: 'center'});
              doc.setFont('THSarabunNew', 'normal');
              var str = 'Page ' + doc.internal.getNumberOfPages();
              if (typeof doc.putTotalPages === 'function') {
                str = str + ' of ' + totalPagesExp
              }
              doc.setFontSize(12);
              var pageSize = doc.internal.pageSize;
              var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
              doc.text('วันที่พิมพ์ ' + date, 8, pageHeight - 8,);
            },
          });
          if (count !== Object.keys(group).length -1) {
            doc.addPage();
          }
          count ++
        })


        var totalPagesExp = '{total_pages_count_string}';

        if (typeof doc.putTotalPages === 'function') {
          doc.putTotalPages(totalPagesExp);
        }
        doc.save(`ป.17ค่าน้ำ-` + moment().format('DD-MM-YYYY') + '.pdf');
      }
    },
    created() {
      if (!moduleBookList.isRegistered) {
        this.$store.registerModule('bookLists', moduleBookList);
        moduleBookList.isRegistered = true;
      }
    }
  }
</script>
