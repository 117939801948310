<template>
  <div class="vx-row">
    <div class="vx-col w-full mb-3">
      <vx-card title="บัญชี/รอบบันทึกค่าบริการ">
        <div class="vx-row">
          <div class="vx-col md:w-1/3 sm:w-1/4 xs:w-1/1 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>รหัสรอบบิล:</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <v-select :options="billConfigs" @input="setBillConfigs" :clearable="false" v-model="selectedBillConfig"
                          class="mb-4 md:mb-0"/>
              </div>
            </div>
          </div>
          <div class="vx-col md:w-1/3 sm:w-1/4 xs:w-1/1 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>ปีงบประมาณ:</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full" v-model="year" readonly/>
              </div>
            </div>
          </div>
          <div class="vx-col md:w-1/3 sm:w-1/4 xs:w-1/1 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>รอบที่:</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full" v-model="yearth" readonly/>
              </div>
            </div>
          </div>
        </div>
        <div class="vx-row mt-5">
          <div class="vx-col md:w-1/3 sm:w-1/4 xs:w-1/1 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>เลือกพื้นที่/กลุ่ม</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <v-select :options="areas" :value="areas" :clearable="false" v-model="selectedArea"/>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
    <vx-card class="mb-4 ml-4 mr-4">
      <marquee-text class="pl-24" :repeat="1" :duration="50">
        <h3>{{notice}}</h3>
      </marquee-text>
    </vx-card>
    <div id="data-list-list-view" class="vx-col w-full mb-base data-list-container">
      <vs-table :data="[]">
        <template slot="thead">
          <vs-th>ลำดับที่</vs-th>
          <vs-th>ชื่อรายงาน</vs-th>
          <vs-th>พิมพ์รายงาน</vs-th>
        </template>
        <template>
          <vs-tr>
            <vs-td><p class="product-name font-medium">1</p></vs-td>
            <vs-td><p class="product-name font-medium">ป.17 ค่าน้ำ</p></vs-td>
            <vs-td class="whitespace-no-wrap">
              <PDFP17Prapa :billConfig="selectedBillConfig" :area="selectedArea" :org="org" :month="yearth"/>
              <ExcelPDFP17Prapa :billConfig="selectedBillConfig" :area="selectedArea" :org="org"/>
              <ReceiveBillDebtReport v-if="org.local" :imgBase64="imgSignBase64"
                                 :imgLogoBase64="imgLogoBase64" :billConfig="selectedBillConfig" :area="selectedArea" :org="org"
                                 class="mr-4"/>
              <ReceiveBillDebtReport590 v-if="org.local" :imgBase64="imgSignBase64"
                                 :imgLogoBase64="imgLogoBase64" :billConfig="selectedBillConfig" :area="selectedArea" :org="org"
                                 class="mr-4"/>
              <BillDebtReportXL v-if="org.local" :imgBase64="imgSignBase64"
                                 :imgLogoBase64="imgLogoBase64" :billConfig="selectedBillConfig" :area="selectedArea" :org="org"
                                 class="mr-4"/>
            </vs-td>
           </vs-tr>
          <vs-tr>
            <vs-td><p class="product-name font-medium">2</p></vs-td>
            <vs-td><p class="product-name font-medium">ป.17 ค่าน้ำเฉพาะลูกหนี้</p></vs-td>
            <vs-td class="whitespace-no-wrap">
              <P17PrapaDebtReport :billConfig="selectedBillConfig" :area="selectedArea" :org="org" :status="0" :month="yearth"/>
              <ExcelP17PrapaDebtReport :billConfig="selectedBillConfig" :area="selectedArea" :org="org" :status="0" :month="yearth"/>
              <TempP17PrapaDebtReport :billConfig="selectedBillConfig" :area="selectedArea" :org="org" :status="0"
                                      :imgBase64="imgSignBase64"
                                      :imgLogoBase64="imgLogoBase64"
                                      :month="yearth"/>
            </vs-td>
          </vs-tr>
          <vs-tr>
            <vs-td><p class="product-name font-medium">3</p></vs-td>
            <vs-td><p class="product-name font-medium">ป.17 ค่าน้ำเฉพาะลูกหนี้ทุกบิล</p></vs-td>
            <vs-td class="whitespace-no-wrap" v-if="!(currentTime > 7 && currentTime < 16)">
              <P17PrapaDebtByAreaReport :billConfig="selectedBillConfig" :area="selectedArea" :org="org" :status="0" :month="yearth"/>
              <P17PrapaDebtByAreaGroupReport :billConfig="selectedBillConfig" :area="selectedArea" :org="org" :status="0" :month="yearth"/>
              <ExcelP17PrapaDebtByAreaReport :billConfig="selectedBillConfig" :area="selectedArea" :org="org"
                                             :status="0" :month="yearth"/>
            </vs-td>
            <vs-td v-if="currentTime > 7 && currentTime < 16"><p class="product-name font-medium">ดูรายงานได้ตั้งแต่เวลา 16.00 - 8.00 น.ขออภัยในความไม่สะดวก</p></vs-td>
          </vs-tr>
          <vs-tr>
            <vs-td><p class="product-name font-medium">4</p></vs-td>
            <vs-td><p class="product-name font-medium">ป.17 ค่าบำบัดน้ำเสีย</p></vs-td>
            <vs-td class="whitespace-no-wrap" v-if="!(currentTime > 7 && currentTime < 16)">
              <PDFP17WasteWater :billConfig="selectedBillConfig" :area="selectedArea" :org="org"/>
            </vs-td>
            <vs-td v-if="currentTime > 7 && currentTime < 16"><p class="product-name font-medium">ดูรายงานได้ตั้งแต่เวลา 16.00 - 8.00 น.ขออภัยในความไม่สะดวก</p></vs-td>
          </vs-tr>
          <vs-tr>
            <vs-td><p class="product-name font-medium">5</p></vs-td>
            <vs-td><p class="product-name font-medium">ป.17 ค่าขยะ</p></vs-td>
            <vs-td class="whitespace-no-wrap" v-if="!(currentTime > 7 && currentTime < 16)">
              <PDFP17Garbage :billConfig="selectedBillConfig" :area="selectedArea" :org="org"/>
              <P17GarbageExcelReport :billConfig="selectedBillConfig" :area="selectedArea" :org="org"/>
            </vs-td>
            <vs-td v-if="currentTime > 7 && currentTime < 16"><p class="product-name font-medium">ดูรายงานได้ตั้งแต่เวลา 16.00 - 8.00 น.ขออภัยในความไม่สะดวก</p></vs-td>
          </vs-tr>
          <vs-tr>
            <vs-td><p class="product-name font-medium">6</p></vs-td>
            <vs-td><p class="product-name font-medium">ป.17 ค่าขยะเฉพาะลูกหนี้</p></vs-td>
            <vs-td class="whitespace-no-wrap" v-if="!(currentTime > 7 && currentTime < 16)">
              <P17GarbageDebtReport :billConfig="selectedBillConfig" :area="selectedArea" :org="org"/>
              <P17GarbageDebtExcelReport :billConfig="selectedBillConfig" :area="selectedArea" :org="org"/>
            </vs-td>
            <vs-td v-if="currentTime > 7 && currentTime < 16"><p class="product-name font-medium">ดูรายงานได้ตั้งแต่เวลา 16.00 - 8.00 น.ขออภัยในความไม่สะดวก</p></vs-td>
          </vs-tr>

          <vs-tr>
            <vs-td><p class="product-name font-medium">6.1</p></vs-td>
            <vs-td><p class="product-name font-medium">ป.17 ค่าขยะเฉพาะลูกหนี้ทุกบิล</p></vs-td>
            <vs-td class="whitespace-no-wrap" v-if="!(currentTime > 7 && currentTime < 16)">
              <P17KayaDebtByAreaReport :billConfig="selectedBillConfig" :area="selectedArea" :org="org"
                                             :status="0" :month="yearth"/>
              <ExcelP17KayaDebtByAreaReport :billConfig="selectedBillConfig" :area="selectedArea" :org="org"
                                             :status="0" :month="yearth"/>
            </vs-td>
            <vs-td v-if="currentTime > 7 && currentTime < 16"><p class="product-name font-medium">ดูรายงานได้ตั้งแต่เวลา 16.00 - 8.00 น.ขออภัยในความไม่สะดวก</p></vs-td>
          </vs-tr>
<!--          <vs-tr>-->
<!--            <vs-td><p class="product-name font-medium">6</p></vs-td>-->
<!--            <vs-td><p class="product-name font-medium">ป.17 สรุปรายเดือน</p></vs-td>-->
<!--            <vs-td class="whitespace-no-wrap">-->
<!--              <P17MonthlyReport :billConfig="selectedBillConfig" :area="selectedArea" :org="org"/>-->
<!--            </vs-td>-->
<!--          </vs-tr>-->
          <vs-tr>
            <vs-td><p class="product-name font-medium">7</p></vs-td>
            <vs-td><p class="product-name font-medium">สรุปยอดตั้งเก็บ</p></vs-td>
            <vs-td class="whitespace-no-wrap" v-if="!(currentTime > 7 && currentTime < 16)">
              <PDFP17PrapaSummary :billConfig="selectedBillConfig" :area="selectedArea" :org="org" :status="''"
                                  :textTitle="'ยอดตั้งเก็บ'"/>
            </vs-td>
            <vs-td v-if="currentTime > 7 && currentTime < 16"><p class="product-name font-medium">ดูรายงานได้ตั้งแต่เวลา 16.00 - 8.00 น.ขออภัยในความไม่สะดวก</p></vs-td>
          </vs-tr>
          <vs-tr>
            <vs-td><p class="product-name font-medium">8</p></vs-td>
            <vs-td><p class="product-name font-medium">สรุปยอดเก็บได้</p></vs-td>
            <vs-td class="whitespace-no-wrap" v-if="!(currentTime > 7 && currentTime < 16)">
              <PDFP17PrapaSummary :billConfig="selectedBillConfig" :area="selectedArea" :org="org" :status="'1'"
                                  :textTitle="'ยอดเก็บได้'"/>
            </vs-td>
            <vs-td v-if="currentTime > 7 && currentTime < 16"><p class="product-name font-medium">ดูรายงานได้ตั้งแต่เวลา 16.00 - 8.00 น.ขออภัยในความไม่สะดวก</p></vs-td>
          </vs-tr>
          <vs-tr>
            <vs-td><p class="product-name font-medium">9</p></vs-td>
            <vs-td><p class="product-name font-medium">สรุปยอดหนี้ค้างชำระ</p></vs-td>
            <vs-td class="whitespace-no-wrap" v-if="!(currentTime > 7 && currentTime < 16)">
              <PDFP17PrapaSummary :billConfig="selectedBillConfig" :area="selectedArea" :org="org" :status="'0'"
                                  :textTitle="'ยอดหนี้ค้างชำระ'"/>
            </vs-td>
            <vs-td v-if="currentTime > 7 && currentTime < 16"><p class="product-name font-medium">ดูรายงานได้ตั้งแต่เวลา 16.00 - 8.00 น.ขออภัยในความไม่สะดวก</p></vs-td>
          </vs-tr>
          <vs-tr>
            <vs-td><p class="product-name font-medium">10</p></vs-td>
            <vs-td><p class="product-name font-medium">สรุปยอดทั้งหมด</p></vs-td>
            <vs-td class="whitespace-no-wrap" v-if="!(currentTime > 7 && currentTime < 16)">
              <P17PrapaSummaryReport :billConfig="selectedBillConfig" :area="selectedArea" :org="org" :status="'0'" :textTitle="'ยอดทั้งหมด'"/>
              <P17PrapaSummaryExcelReport class="ml-5 w-5 h-5" :billConfig="selectedBillConfig" :area="selectedArea" :org="org" :status="'0'" :textTitle="'ยอดทั้งหมด'"/>
            </vs-td>
            <vs-td v-if="currentTime > 7 && currentTime < 16"><p class="product-name font-medium">ดูรายงานได้ตั้งแต่เวลา 16.00 - 8.00 น.ขออภัยในความไม่สะดวก</p></vs-td>
          </vs-tr>
          <!--          <vs-tr>-->
          <!--            <vs-td><p class="product-name font-medium">7</p></vs-td>-->
          <!--            <vs-td><p class="product-name font-medium">สรุปยอดหนี้ค้างชำระ</p></vs-td>-->
          <!--            <vs-td class="whitespace-no-wrap">-->
          <!--              <PDFP17PrapaSummary :billConfig="selectedBillConfig" :area="selectedArea" :org="org"/>-->
          <!--            </vs-td>-->
          <!--          </vs-tr>-->
          <!--          <vs-tr>-->
          <!--            <vs-td><p class="product-name font-medium">4</p></vs-td>-->
          <!--            <vs-td><p class="product-name font-medium">บัญชีลูกหนี้รายเดือน</p></vs-td>-->
          <!--            <vs-td class="whitespace-no-wrap">-->
          <!--              <PDFOverdueDeptor/>-->
          <!--            </vs-td>-->
          <!--          </vs-tr>-->
          <!--          <vs-tr>-->
          <!--            <vs-td><p class="product-name font-medium">5</p></vs-td>-->
          <!--            <vs-td><p class="product-name font-medium">บัญชีลูกหนี้ทั้งหมด</p></vs-td>-->
          <!--            <vs-td class="whitespace-no-wrap">-->
          <!--              <PDFOverdueDeptor/>-->
          <!--            </vs-td>-->
          <!--          </vs-tr>-->
          <!--          <vs-tr>-->
          <!--            <vs-td><p class="product-name font-medium">6</p></vs-td>-->
          <!--            <vs-td><p class="product-name font-medium">บัญชีประจำตัวผู้เก็บเงินป.32 ประจำวัน สรุปยอดทั้งหมด</p></vs-td>-->
          <!--            <vs-td class="whitespace-no-wrap">-->
          <!--              <PDFP32Summary/>-->
          <!--            </vs-td>-->
          <!--          </vs-tr>-->
          <!--          <vs-tr>-->
          <!--            <vs-td><p class="product-name font-medium">5</p></vs-td>-->
          <!--            <vs-td><p class="product-name font-medium">ป.17 ค่าน้ำ สรุปทุกเดือน</p></vs-td>-->
          <!--            <vs-td class="whitespace-no-wrap">-->
          <!--              <PDFP17PrapaSummary/>-->
          <!--            </vs-td>-->
          <!--          </vs-tr>-->
          <!--          <vs-tr>-->
          <!--            <vs-td><p class="product-name font-medium">8</p></vs-td>-->
          <!--            <vs-td><p class="product-name font-medium">สรุปยอดตั้งเก็บ</p></vs-td>-->
          <!--            <vs-td class="whitespace-no-wrap">-->
          <!--              <PDFP17PrapaSummary/>-->
          <!--            </vs-td>-->
          <!--          </vs-tr>-->
          <vs-tr>
            <vs-td><p class="product-name font-medium">11</p></vs-td>
            <vs-td><p class="product-name font-medium">ใบบันทึกเลขมาตร</p></vs-td>
            <vs-td class="whitespace-no-wrap">
              <PDFMeterNumber :billConfig="selectedBillConfig" :area="selectedArea" :org="org"/>
            </vs-td>
          </vs-tr>
          <!-- <vs-tr>
            <vs-td><p class="product-name font-medium">9</p></vs-td>
            <vs-td><p class="product-name font-medium">รายชื่อหักค่าน้ำประปาผ่านธนาคาร</p></vs-td>
            <vs-td class="whitespace-no-wrap">
              <feather-icon icon="PrinterIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" @click="excel()"/>
            </vs-td>
          </vs-tr> -->
        </template>
      </vs-table>
    </div>
  </div>
</template>

<script>

  import vSelect from "vue-select";
  import moduleConfig from "@/store/config/moduleConfig.js";
  import moduleBillConfig from "@/store/billconfig/moduleBillConfig.js";
  import moduleArea from "@/store/area/moduleArea.js";
  import P17MonthlyReport from "../pdf/P17MonthlyReport.vue";
  import PDFP17Prapa from "../pdf/P17PrapaReport.vue";
  import ExcelPDFP17Prapa from "../pdf/ExcelP17PrapaReport.vue";
  import P17PrapaDebtReport from "../pdf/P17PrapaDebtReport.vue";
  import TempP17PrapaDebtReport from "../pdf/TempP17PrapaDebtReport.vue";
  import ExcelP17PrapaDebtReport from "../pdf/ExcelP17PrapaDebtReport.vue";
  import P17PrapaDebtByAreaReport from "../pdf/P17PrapaDebtByAreaReport.vue";
  import P17PrapaDebtByAreaGroupReport from "../pdf/P17PrapaDebtByAreaGroupReport.vue";
  import ExcelP17PrapaDebtByAreaReport from "../pdf/ExcelP17PrapaDebtByAreaReport.vue";
  import PDFP17WasteWater from "../pdf/P17WasteWaterReport.vue";
  import PDFP17Garbage from "../pdf/P17GarbageReport.vue";
  import P17GarbageDebtReport from "../pdf/P17GarbageDebtReport.vue";
  import P17GarbageExcelReport from "../pdf/P17GarbageExcelReport.vue";
  import P17GarbageDebtExcelReport from "../pdf/P17GarbageDebtExcelReport.vue";
  import PDFOverdueDeptor from "../pdf/OverdueDeptorReport.vue";
  import PDFP32Summary from "../pdf/P32SummaryReport.vue";
  import PDFP17PrapaSummary from "../pdf/P17PrapaSummary.vue";
  import PDFMeterNumber from '../pdf/SaveMeterNumberReport.vue'
  import P17PrapaSummaryReport from '../pdf/P17PrapaSummaryReport.vue'
  import P17PrapaSummaryExcelReport from '../pdf/P17PrapaSummaryExcelReport.vue'
  import PDFPrapaAllDebt from '../pdf/P17PrapaAllDebt.vue'
  import ReceiveBillDebtReport from "../pdf/ReceiveBillDebtReport.vue";
  import ReceiveBillDebtReport590 from "../pdf/ReceiveBillDebtReport590.vue";
  import BillDebtReportXL from "../pdf/BillDebtReportXL.vue";
  import P17KayaDebtByAreaReport from "../pdf/P17KayaDebtByAreaReport.vue";
  import ExcelP17KayaDebtByAreaReport from "../pdf/ExcelP17KayaDebtByAreaReport.vue";
  import * as XLSX from 'xlsx';
  import moment from 'moment';

  export default {
    components: {
      vSelect,
      TempP17PrapaDebtReport,
      ExcelPDFP17Prapa,
      ExcelP17PrapaDebtReport,
      ExcelP17PrapaDebtByAreaReport,
      PDFP17Prapa,
      P17PrapaDebtReport,
      PDFP17WasteWater,
      PDFP17Garbage,
      PDFOverdueDeptor,
      PDFP32Summary,
      PDFP17PrapaSummary,
      P17MonthlyReport,
      PDFMeterNumber,
      PDFPrapaAllDebt,
      P17PrapaDebtByAreaReport,
      P17PrapaSummaryReport,
      P17PrapaSummaryExcelReport,
      ReceiveBillDebtReport,
      ReceiveBillDebtReport590,
      P17PrapaDebtByAreaGroupReport,
      BillDebtReportXL,
      P17GarbageExcelReport,
      P17GarbageDebtReport,
      P17GarbageDebtExcelReport,
      P17KayaDebtByAreaReport,
      ExcelP17KayaDebtByAreaReport
    },
    data() {
      return {
        selectedArea: {label: '', value: ''},
        selectedBillConfig: {label: '', value: '', data: ''},
        year: '',
        yearth: '',
        imgSignBase64: '',
        imgLogoBase64: '',
        currentTime: moment().format('HH'),
        notice: '***ระบบจะแสดงข้อมูลรายงานได้ตั้งแต่เวลา 16.00 - 8.00 น. ขออภัยในความไม่สะดวก***',
        baseUrl: 'http://188.166.177.25/',
      }
    },
    computed: {
      org() {
        return this.$store.state.config.item;
      },
      areas() {
        let options = [];
        this.$store.state.area.items.forEach(function (item) {
          options.push({
            label: item.A02,
            value: item.A01
          });
        });
        // options.unshift({
        //   label: 'ทั้งหมด',
        //   value: 'all',
        //   data: this.$store.state.area.items
        // });
        return options;
      },
      billConfigs() {
        let options = [];
        this.$store.state.billConfigs.items.forEach(function (item) {
          options.push({
            label: item.CB01  + ' (' + item.CB04+ ')',
            value: item.CB01,
            data: item
          })
        });
        return options;
      },
    },
    methods: {
      maintainDialog() {
        this.$vs.dialog({
          type: 'alert',
          color: 'warning',
          title: `ขอปรับปรุงระบบ`,
          text: 'รายงานจะใช้งานได้หลังวันที่ 1/6/2565',
          acceptText: 'ปิด'
        })
      },
      async init() {
        const area = await this.$store.state.area.items[0];
        const bill = await this.$store.state.billConfigs.items[0];
        this.selectedArea = {
          label: area.A02,
          value: area.A01
        };
        this.selectedBillConfig = {
          label: bill.CB01  + ' (' + bill.CB04+ ')',
          value: bill.CB01,
          data: bill
        };
        this.year = bill.CB03;
        this.yearth = bill.CB04;
        const url = await this.$store.state.config.item.report_image_url;
        if(url !== '' && url !== undefined && url !== null) {
          this.imgSignBase64 = await this.getDataUri(this.baseUrl + url);
        }
        const logoUrl = await this.$store.state.config.item.logo_image_url;
        if(logoUrl !== '' && logoUrl !== undefined && logoUrl !== null) {
          this.imgLogoBase64 = await this.getDataUri(this.baseUrl + logoUrl);
        }
        this.$vs.loading.close();
      },
      getDataUri(url) {
        return new Promise(resolve => {
          const image = new Image();
          image.setAttribute('crossOrigin', 'anonymous');
          image.onload = function () {
            const canvas = document.createElement('canvas');
            canvas.width = this.width;
            canvas.height = this.height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(this, 0, 0);
            const dataURL = canvas.toDataURL("image/png");
            resolve(dataURL);
          };
          image.src = url;
        })
      },
      setBillConfigs(item) {
        this.year = item.data.CB03;
        this.yearth = item.data.CB04;
      },
      excel() {
        var itemsFormatted = [];
        for (let i = 0; i < 100; i++) {
          itemsFormatted.push({
            'ลำดับที่': i,
            'เลขที่ผู้ใช้น้ำ': '01010126',
            'เลขมาตรวัด': '501009794',
            'ชื่อ-สกุลผู้ใช้น้ำ': 'นายวินัย เพชรอุไร',
            'เลขที่บัญชี': '929-0-18320-9',
            'ชื่อบัญชี': 'น.ส.ศศิวิมล เพชรอุไร',
            'เดือนค้างชำระ': this.yearth,
            'เลขที่บิล': '63-116',
            'รวมยอดค้าง': '32.10'
          });
        }
        var fileName = 'หักผ่านธนาคาร.xlsx';
        var ws = XLSX.utils.json_to_sheet(itemsFormatted);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "People");
        XLSX.writeFile(wb, fileName);
      }
    },
    async created() {
      this.$vs.loading();
      if (!moduleBillConfig.isRegistered) {
        this.$store.registerModule('billConfigs', moduleBillConfig)
        moduleBillConfig.isRegistered = true
      }
      if (!moduleArea.isRegistered) {
        this.$store.registerModule('area', moduleArea);
        moduleArea.isRegistered = true
      }
      if (!moduleConfig.isRegistered) {
        this.$store.registerModule('config', moduleConfig)
        moduleConfig.isRegistered = true
      }
      await this.$store.dispatch("billConfigs/getDataListMenuItems");
      await this.$store.dispatch("area/fetchDataListItems");
      await this.$store.dispatch("config/fetchDataItem");
      setTimeout(() => {
        this.$emit('input', this.init())
      }, 1000)
    },
  }
</script>

<style lang="scss">
  #data-list-list-view {
    .vs-con-table {

      @media (max-width: 689px) {
        .vs-table--search {
          margin-left: 0;
          max-width: unset;
          width: 100%;

          .vs-table--search-input {
            width: 100%;
          }
        }
      }

      @media (max-width: 461px) {
        .items-per-page-handler {
          display: none;
        }
      }

      @media (max-width: 341px) {
        .data-list-btn-container {
          width: 100%;

          .dd-actions,
          .btn-add-new {
            width: 100%;
            margin-right: 0 !important;
          }
        }
      }

      .product-name {
        max-width: 23rem;
      }

      .vs-table--header {
        display: flex;
        flex-wrap: wrap;
        margin-left: 1.5rem;
        margin-right: 1.5rem;

        > span {
          display: flex;
          flex-grow: 1;
        }

        .vs-table--search {
          padding-top: 0;

          .vs-table--search-input {
            padding: 0.9rem 2.5rem;
            font-size: 1rem;

            & + i {
              left: 1rem;
            }

            &:focus + i {
              left: 1rem;
            }
          }
        }
      }

      .vs-table--not-data {
        display: none;
      }

      .vs-table {
        border-collapse: separate;
        border-spacing: 0 1.3rem;
        padding: 0;

        tr {
          box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .05);

          td {
            padding: 20px;

            &:first-child {
              border-top-left-radius: .5rem;
              border-bottom-left-radius: .5rem;
            }

            &:last-child {
              border-top-right-radius: .5rem;
              border-bottom-right-radius: .5rem;
            }
          }

          td.td-check {
            padding: 20px !important;
          }
        }
      }

      .vs-table--thead {
        th {
          padding-top: 0;
          padding-bottom: 0;

          .vs-table-text {
            text-transform: uppercase;
            font-weight: 600;
          }
        }

        th.td-check {
          padding: 0 15px !important;
        }

        tr {
          background: none;
          box-shadow: none;
        }
      }

      .vs-table--pagination {
        justify-content: center;
      }
    }
  }
</style>
