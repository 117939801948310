<template>
  <feather-icon icon="FileTextIcon" svgClasses="ml-5 w-5 h-5 hover:text-primary stroke-current" @click="getBookLists()"/>
</template>

<script>
  import moment from "moment";
  import moduleBookList from "@/store/booklist/moduleBookList.js";
  import {formatNumber2Digit, formatNumber} from "./assets/utils";
  import * as XLSX from "xlsx";
  export default {
    props: ['billConfig', 'area', 'org', 'status'],
    components: {
    },
    name: "P17PrapaReport",
    data() {
      return {
        thmonth: ['', 'มกราคม', 'กุมภาพันธ์', 'มีนาคม',
          'เมษายน', 'พฤษภาคม', 'มิถุนายน', 'กรกฎาคม', 'สิงหาคม', 'กันยายน',
          'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'],
      }
    },
    methods: {
      maintainDialog() {
        this.$vs.dialog({
          type: 'alert',
          color: 'warning',
          title: `ขอปรับปรุงระบบ`,
          text: 'รายงานจะใช้งานได้หลังวันที่ 1/6/2565',
          acceptText: 'ปิด'
        })
      },
      currentThaiDate() {
        const date = moment().format('DD/MM/YYYY');
        const dateSplit = date.split('/');
        const year = parseInt(dateSplit[2]) + 543;
        return dateSplit[0] + '/' + dateSplit[1] + '/' + year;
      },
      warningDialog() {
        this.$vs.dialog({
          type: 'alert',
          color: 'warning',
          title: `คำเตือน`,
          text: 'ไม่มีรายชื่อในรายงาน ป.17 ค่าขยะ',
          acceptText: 'ปิด'
        })
      },
      async getBookLists() {
        this.$vs.loading();
        let payload = {};
        if (this.status !== undefined) {
          payload.B10 = this.status;
        }
        await this.$store.dispatch("bookLists/fetchDebtByAreaDataListItems", payload).then((res) => {
          if (res.status === 200 && res.data.length !== 0) {
            const booklists = res.data;
            this.generatePDF(booklists);
            this.$vs.loading.close();
          } else {
            this.warningDialog();
            this.$vs.loading.close();
          }
        }).catch((error) => {
          this.warningDialog();
          this.$vs.loading.close();
        });
      },
      formatDate(text) {
        try {
          const date = moment(text).format('DD/MM/YYYY');
          const dateSplit = date.split('/');
          const year = parseInt(dateSplit[2]) + 543;
          return dateSplit[0] + '/' + dateSplit[1] + '/' + year;
        }catch (e) {
          return text
        }
      },
      generatePDF(bookLists) {
        const array = [];
        for (let i = 0; i < bookLists.length; i++) {
          const data = bookLists[i];
          for (let j = 0; j < data.list.length; j++) {
            const bookList = data.list[j];
            const sum = parseFloat(bookList.BA15) + parseFloat(bookList.BA12) + parseFloat(bookList.BA13)
            array.push({
              'เขต':data.member_area,
              'ชื่อ':data.member_name,
              'เลขที่ใบแจ้งหนี้':bookList.B22,
              'รอบบิล':bookList.B01,
              'รหัสสมาชิก':bookList.B03,
              'หน่วยที่ใช้':bookList.B21,
              'ค่าขยะ':bookList.BA15,
              'ค่าบริการ':bookList.BA12,
              'ภาษีฯ':bookList.BA13,
              'รวมทั้งสิ้น':formatNumber(sum)
            })
          }
        }
        let fileName = `ป.17ค่าขยะ-` + moment().format('DD-MM-YYYY') + '.xlsx';
        let ws = XLSX.utils.json_to_sheet(array);
        let wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, fileName);
        XLSX.writeFile(wb, fileName);
      }
    },
    created() {
      if (!moduleBookList.isRegistered) {
        this.$store.registerModule('bookLists', moduleBookList);
        moduleBookList.isRegistered = true;
      }
    }
  }
</script>
