export function formatNumber(value) {
  if (typeof value === "number") {
    return parseFloat(ToFixed(value)).toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2});
  } else {
    return parseFloat(ToFixed(value)).toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2})
  }
}
function ToFixed(num,dec){
  if(typeof(pre) != 'undefined' && pre != null){ var decimals=dec;  } else{var decimals=2;}

  num *= Math.pow(10,decimals);
  num = (Math.round(num,decimals) + (((num - Math.round(num,decimals))>=0.4)?1:0)) / Math.pow(10,decimals);
  return num.toFixed(decimals);
}
export function formatNumber2Digit(value) {
  if (typeof value === "number") {
    return ToFixed(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    return parseFloat(ToFixed(value)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }
}
export function format4Digit(value) {
  if (typeof value === "number") {
    return value.toLocaleString("en-US", {maximumFractionDigits: 3, minimumFractionDigits: 3});
  } else {
    return parseFloat(value).toLocaleString("en-US", {maximumFractionDigits: 3, minimumFractionDigits: 3})
  }
}

export function formatFloat(value) {
  return parseFloat(parseFloat(ToFixed(value)).toLocaleString("en-US", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  }).replace(',', ''))
}

export function formatFloat4Digit(value) {
  return parseFloat(parseFloat(value).toLocaleString("en-US", {
    maximumFractionDigits: 4,
    minimumFractionDigits: 4
  }).replace(',', ''))
}

export function format2Digit(value) {
  return parseFloat(ToFixed(value)).toLocaleString("en-US", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  })
}
