<template>
  <feather-icon icon="PrinterIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" @click="getBookLists()"/>
</template>

<script>
  import jsPDF from "jspdf";
  import 'jspdf-autotable'

  const fs = require('fs');
  import {font} from "./assets/THSarabunNew-normal";
  import {font_bold} from "./assets/THSarabunNew-bold";
  import moment from "moment";
  import {formatNumber2Digit, format4Digit, formatNumber} from "./assets/utils";

  export default {
    props: ['billConfig', 'area', 'org', 'status', 'textTitle'],
    components: {
      jsPDF,
    },
    name: "RealUserReport",
    data() {
      return {
        district: 'องค์การบริหารส่วนตำบลจำปาโมง',
        address: 'ต.จำปาโมง อ.บ้านผือ จ.อุดรธานี้',
        month: 'ตุลาคม-61',
        id_bill: '(62-001)',
        date: '',
      }
    },
    methods: {
      maintainDialog() {
        this.$vs.dialog({
          type: 'alert',
          color: 'warning',
          title: `ขอปรับปรุงระบบ`,
          text: 'รายงานจะใช้งานได้หลังวันที่ 1/6/2565',
          acceptText: 'ปิด'
        })
      },
      groupBy(xs, key) {
        return xs.reduce(function (rv, x) {
          (rv[x[key]] = rv[x[key]] || []).push(x);
          return rv;
        }, {});
      },
      warningDialog() {
        this.$vs.dialog({
          type: 'alert',
          color: 'warning',
          title: `คำเตือน`,
          text: 'ไม่มีรายชื่อในรายงานสรุป' + this.textTitle,
          acceptText: 'ปิด'
        })
      },
      async getBookLists() {
        this.$vs.loading();
        let payload = {};
        payload.B01 = this.billConfig.value;
        await this.$store.dispatch("bookLists/fetchReportDataListItems", payload).then((res) => {
          if (res.status === 200 && res.data.length !== 0) {
            const booklists = res.data;
            this.generatePDF(booklists, this.billConfig.label);
            this.$vs.loading.close();
          } else {
            this.warningDialog();
            this.$vs.loading.close();
          }
        }).catch((error) => {
          this.warningDialog();
          this.$vs.loading.close();
        });
      },
      generatePDF(bookLists, billConfig) {
        const doc = new jsPDF({
          orientation: "portrait",
          unit: "mm",
          format: [279.4, 210]
        });
        const status = this.status;
        const org = this.org;
        const title = 'บัญชีสรุป' + this.textTitle
        const date = this.currentThaiDate()
        let array = [];
        let sumUnit = 0;
        let sumBA11 = 0.0;
        let sumBA12 = 0.0;
        let sumBA13 = 0.0;
        let sumTotal = 0.0;
        let sumPaidTotal = 0.0;
        let sumUnPaidTotal = 0.0;
        for (let i = 0; i < bookLists.length; i++) {
          const data = bookLists[i]
          array.push([data.area,
            formatNumber(data.unit).replace(".00",""),
            formatNumber(data.BA11),
            formatNumber(data.BA12),
            formatNumber(data.BA13),
            formatNumber(data.total),
            formatNumber(data.paidTotal),
            formatNumber(data.unPaidTotal)])
          sumUnit = sumUnit + parseInt(data.unit);
          sumBA11 = sumBA11 + parseFloat(data.BA11);
          sumBA12 = sumBA12 + parseFloat(data.BA12);
          sumBA13 = sumBA13 + parseFloat(data.BA13);
          sumTotal = sumTotal + parseFloat(data.total);
          sumPaidTotal = sumPaidTotal + parseFloat(data.paidTotal);
          sumUnPaidTotal = sumUnPaidTotal + parseFloat(data.unPaidTotal);
        }
        array.push([
          'รวมทั้งสิ้น',
          formatNumber(sumUnit).replace(".00",""),
          formatNumber(sumBA11),
          formatNumber(sumBA12),
          formatNumber(sumBA13),
          formatNumber(sumTotal),
          formatNumber(sumPaidTotal),
          formatNumber(sumUnPaidTotal),
        ]);
        var totalPagesExp = '{total_pages_count_string}';
        doc.addFileToVFS('THSarabunNew-normal.ttf', font);
        doc.addFileToVFS('THSarabunNew-bold.ttf', font_bold);
        doc.addFont('THSarabunNew-normal.ttf', 'THSarabunNew', 'normal');
        doc.addFont('THSarabunNew-bold.ttf', 'THSarabunNew', 'bold');
        doc.autoTable({
          showHead: 'everyPage',
          showFoot: 'everyPage',
          styles: {font: 'THSarabunNew', textColor: 0, halign: 'right', fontSize: 14},
          headStyles: {fillColor: [255, 255, 255], textColor: 0, halign: 'right'},
          margin: {left: 8, top: 35, right: 8},
          theme: 'plain',
          head: [
            ['เขต', 'หน่วยที่ใช้', 'ค่าน้ำ', 'ค่าบริการ', 'ภาษี', 'รวมยอดตั้งเก็บ', 'รวมยอดเก็บได้', 'รวมยอดหนี้ค้างชำระ']
          ],
          body: array,
          didDrawPage: function (data) {
            // Header
            doc.setFont('THSarabunNew', 'bold');
            doc.setFontSize(16);
            doc.text(org.Org01, doc.internal.pageSize.getWidth() / 2, 10, {align: 'center'});
            doc.text(org.Org03, doc.internal.pageSize.getWidth() / 2, 18, {align: 'center'});
            doc.setFontSize(14);
            doc.text(title + 'ประจำเดือน ' + billConfig, doc.internal.pageSize.getWidth() / 2, 25, {align: 'center'});
            doc.setFont('THSarabunNew', 'normal');
          },
        });
        const pages = doc.internal.getNumberOfPages();
        const pageSize = doc.internal.pageSize;
        const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
        for (let j = 1; j < pages + 1; j++) {
          doc.setPage(j);
          doc.addFileToVFS('THSarabunNew-normal.ttf', font);
          doc.addFileToVFS('THSarabunNew-bold.ttf', font_bold);
          doc.addFont('THSarabunNew-normal.ttf', 'THSarabunNew', 'normal');
          doc.addFont('THSarabunNew-bold.ttf', 'THSarabunNew', 'bold');
          doc.setFont('THSarabunNew', 'normal');
          doc.setFontSize(12);
          doc.text('วันที่พิมพ์ ' + date, 10, pageHeight - 8,)
          doc.text(`หน้าที่พิมพ์ ${j} of ${pages}`, 180, pageHeight - 8, {align: 'center'})
        }
        doc.save(`สรุปยอด` + this.textTitle + `-` + moment().format('DD-MM-YYYY') + '.pdf');
      },
      currentThaiDate() {
        const date = moment().format('DD/MM/YYYY');
        const dateSplit = date.split('/');
        const year = parseInt(dateSplit[2]) + 543;
        return dateSplit[0] + '/' + dateSplit[1] + '/' + year;
      }
    }
  }
</script>
