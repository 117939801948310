<template>
  <feather-icon icon="PrinterIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" @click="getBookLists()"/>
</template>

<script>
  import 'jspdf-autotable'
  import jsPDF from "jspdf";
  import {font} from "./assets/THSarabunNew-normal";
  import {font_bold} from "./assets/THSarabunNew-bold";
  import moment from "moment";
  import moduleBookList from "@/store/booklist/moduleBookList.js";
  import FORMATDATE from './assets/dateYearBudda'

  export default {
    props: ['billConfig', 'area', 'org', 'status', 'member_status'],
    components: {
      jsPDF,
    },
    name: "P17PrapaDeptByAreaReport",
    data() {
      return {
        date: '',
      }
    },
    methods: {
      currentThaiDate(){
        const date = moment().format('DD/MM/YYYY');
        const dateSplit = date.split('/');
        const year = parseInt(dateSplit[2]) + 543;
        return dateSplit[0] + '/' + dateSplit[1] + '/' + year;
      },
      warningDialog() {
        this.$vs.dialog({
          type: 'alert',
          color: 'warning',
          title: `คำเตือน`,
          text: 'ไม่มีรายชื่อในรายงาน ป.17 ค่าน้ำ',
          acceptText: 'ปิด'
        })
      },
      async getBookLists() {
        this.$vs.loading();
        await this.$store.dispatch("bookLists/fetchAllDebtListItems").then((res) => {
          if (res.status === 200 && res.data.length !== 0) {
            console.log('res.status === 200')
            const booklists = res.data;
            this.generatePDF(booklists);
            this.$vs.loading.close();
          } else {
            console.log('res.status !== 200')
            this.warningDialog();
            this.$vs.loading.close();
          }
        }).catch((error) => {
          this.warningDialog();
          this.$vs.loading.close();
        });
      },
      generatePDF(bookLists) {
        const doc = new jsPDF({
          orientation: "portrait",
          unit: "mm",
          format: [279.4, 210]
        });
        const org = this.org;
        const district = org.Org01;
        const address = org.Org03;
        const month = bookLists[0].CB04;
        const id_bill = bookLists[0].B01;
        const area = this.area;
        const date = this.currentThaiDate()
        let sumUnit = 0;
        let sumPrapa = 0;
        let sumService = 0;
        let sumVat = 0;
        let sumPrapaVat = 0;
        let sumBA = 0;
        let sum = 0;
        let array = [];
        let index = 0;
        for (let i = 0; i < bookLists.length; i++) {
          const item = bookLists[i];
          item.list.forEach(function (data) {
            index += 1
            let BA11 = 0;
            let BA12 = 0;
            let BA13 = 0;
            let sumBA = 0;
            if (data.BA11 !== undefined && data.BA11 !== null && data.BA11 !== '' && data.BA11 !== 'NaN') {
              BA11 = parseFloat(data.BA11);
              sumPrapa += parseFloat(data.BA11);
              sumBA += parseFloat(data.BA11);
            }
            if (data.BA12 !== undefined && data.BA12 !== null && data.BA12 !== '' && data.BA12 !== 'NaN') {
              BA12 = parseFloat(data.BA12);
              sumService += parseFloat(data.BA12);
              sumBA += parseFloat(data.BA12);
            }
            if (data.BA13 !== undefined && data.BA13 !== null && data.BA13 !== 'NaN') {
              BA13 = ((BA11 + BA12) * 0.07);
              sumBA += BA13;
              sumVat += BA13;
              sumPrapaVat += BA11 + BA13
            }
            if (data.B21 !== undefined && data.B21 !== null && data.B21 !== '' && data.B21 !== 'NaN') {
              sumUnit += parseInt(data.B21);
            }

            sum += sumBA;
            let B22 = ''
            if(data.B22 !== '')
              B22 = data.B22
            array.push([
              index,
              data.B03,
              item.member_name,
              B22,
              parseFloat(BA11).toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2}),
              parseFloat(BA12).toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2}),
              parseFloat(BA13).toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2}),
              parseFloat(sumBA).toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2})
            ]);
          })
        }
        array.push([
          'รวมทั้งสิ้น',
          '',
          '',
          '',
          parseFloat(sumPrapa).toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2}),
          parseFloat(sumService).toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2}),
          parseFloat(sumVat).toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2}),
          parseFloat(sum).toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2}),
        ]);
        array.push([
          'ค่าน้ำ+ภาษี',
          '',
          '',
          '',
          sumPrapaVat.toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2}),
        ]);

        array.push([
          'ค่าบริการ',
          '',
          '',
          '',
          sumVat.toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2}),
        ]);
        var totalPagesExp = '{total_pages_count_string}';
        doc.addFileToVFS('THSarabunNew-normal.ttf', font);
        doc.addFileToVFS('THSarabunNew-bold.ttf', font_bold);
        doc.addFont('THSarabunNew-normal.ttf', 'THSarabunNew', 'normal');
        doc.addFont('THSarabunNew-bold.ttf', 'THSarabunNew', 'bold');
        doc.autoTable({
          showHead: 'everyPage',
          showFoot: 'everyPage',
          styles: {font: 'THSarabunNew', fontSize: 11, textColor: 0},
          margin: {left: 16, top: 35, right: 5},
          theme: 'plain',
          head: [
            [
              {content: area.label, colSpan: 8},
            ],
            [
              'ลำดับที่',
              'รหัส',
              'ชื่อสกุลสมาชิกที่ใช้น้ำ',
              'เลขที่ใบแจ้งหนี้',
              'ค่าน้ำ',
              'ค่าบริการ',
              'ภาษี',
              'รวมเงิน'
            ]
          ],
          body: array,
          didDrawPage: function (data) {
            doc.setFont('THSarabunNew', 'bold');
            doc.setFontSize(16);
            doc.text(district, doc.internal.pageSize.getWidth() / 2, 10, {align: 'center'});
            doc.text(address, doc.internal.pageSize.getWidth() / 2, 16, {align: 'center'});
            doc.setFontSize(14);
            doc.text('บัญชีรายตัวลูกหนี้ ประเภทใช้มาตรวัดน้ำ', doc.internal.pageSize.getWidth() / 2, 25, {align: 'center'});
            doc.setFont('THSarabunNew', 'normal');
            var str = 'Page ' + doc.internal.getNumberOfPages();
            if (typeof doc.putTotalPages === 'function') {
              str = str + ' of ' + totalPagesExp
            }
            doc.setFontSize(12);
            var pageSize = doc.internal.pageSize;
            var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
            doc.text('วันที่พิมพ์ ' + date, 8, pageHeight - 8,);
          },
        });
        if (typeof doc.putTotalPages === 'function') {
          doc.putTotalPages(totalPagesExp);
        }
        const formDateFile = FORMATDATE(new Date(), 'DD-MM-YYYY')
        doc.save(`ป.17ค่าน้ำเฉพาะลูกหนี้ทุกบิล-` + formDateFile + '.pdf');
      }
    },
    created() {
      if (!moduleBookList.isRegistered) {
        this.$store.registerModule('bookLists', moduleBookList);
        moduleBookList.isRegistered = true;
      }
    }
  }
</script>
