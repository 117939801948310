<template>
  <feather-icon icon="PrinterIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" @click="getBookLists()"/>
</template>

<script>
  import jsPDF from "jspdf";
  import 'jspdf-autotable'

  const fs = require('fs');
  import {font} from "./assets/THSarabunNew-normal";
  import {font_bold} from "./assets/THSarabunNew-bold";
  import moment from "moment";
  import {formatNumber2Digit, formatNumber} from "./assets/utils";
  export default {
    props: ['billConfig', 'area', 'org', 'status', 'textTitle'],
    components: {
      jsPDF,
    },
    name: "RealUserReport",
    data() {
      return {
        district: 'องค์การบริหารส่วนตำบลจำปาโมง',
        address: 'ต.จำปาโมง อ.บ้านผือ จ.อุดรธานี้',
        month: 'ตุลาคม-61',
        id_bill: '(62-001)',
        date: '20/01/2020',
      }
    },
    methods: {
      maintainDialog() {
        this.$vs.dialog({
          type: 'alert',
          color: 'warning',
          title: `ขอปรับปรุงระบบ`,
          text: 'รายงานจะใช้งานได้หลังวันที่ 1/6/2565',
          acceptText: 'ปิด'
        })
      },
      currentThaiDate() {
        const date = moment().format('DD/MM/YYYY');
        const dateSplit = date.split('/');
        const year = parseInt(dateSplit[2]) + 543;
        return dateSplit[0] + '/' + dateSplit[1] + '/' + year;
      },
      groupBy(xs, key) {
        return xs.reduce(function (rv, x) {
          (rv[x[key]] = rv[x[key]] || []).push(x);
          return rv;
        }, {});
      },
      warningDialog() {
        this.$vs.dialog({
          type: 'alert',
          color: 'warning',
          title: `คำเตือน`,
          text: 'ไม่มีรายชื่อในรายงานสรุป' + this.textTitle,
          acceptText: 'ปิด'
        })
      },
      async getBookLists() {
        this.$vs.loading();
        let payload = {};
        payload.B01 = this.billConfig.value;
        await this.$store.dispatch("bookLists/fetchDebtDataListItems", payload).then((res) => {
          if (res.status === 200 && res.data.length !== 0) {
            const booklists = res.data;
            this.generatePDF(booklists, this.billConfig.label);
            this.$vs.loading.close();
          } else {
            this.warningDialog();
            this.$vs.loading.close();
          }
        }).catch((error) => {
          console.log(error)
          this.warningDialog();
          this.$vs.loading.close();
        });
      },
      generatePDF(bookLists, billConfig) {
        const doc = new jsPDF({
          orientation: "portrait",
          unit: "mm",
          format: [279.4, 210]
        });
        const status = this.status;
        const org = this.org;
        const title = 'บัญชีสรุป' + this.textTitle
        const date = this.currentThaiDate()
        let array = [];
        bookLists = bookLists.filter(function (item) {
          if (status === '') {
            return item
          } else {
            return item.B10 === status;
          }
        });
        let areaList = [];
        let B21Sum = 0;
        let BA11Sum = 0;
        let BA12Sum = 0;
        let BA13Sum = 0;
        let allSum = 0;
        let sumBA1113 = 0;
        let group = this.groupBy(bookLists, 'member_area');
        Object.keys(group).forEach(function (key) {
          let B21 = 0;
          let sumBA11 = 0.0;
          let sumBA12 = 0.0;
          let sumBA13 = 0.0;
          let allSum = 0;
          let allPaidSum = 0;
          let diffPaid;
          group[key].forEach(function (key) {
            B21 = B21 + parseInt(key['B21'])
            sumBA11 = sumBA11 + parseFloat(key['BA11'])
            sumBA12 = sumBA12 + parseFloat(key['BA12'])
            sumBA13 = sumBA13 + parseFloat(key['BA13'])
            allSum = sumBA11 + sumBA12 + sumBA13
            if (key['B10'] === '1') {
              allPaidSum = sumBA11 + sumBA12 + sumBA13
            }
          });
          diffPaid = allSum - allPaidSum
          areaList.push({
            member_area: key,
            B21: B21,
            BA11: sumBA11,
            BA12: sumBA12,
            BA13: sumBA13,
            sum: allSum,
          })
        })
        areaList.sort((a, b) => parseInt(a['member_area']) - parseInt(b['member_area']));
        areaList.forEach(function (item) {
          array.push([item.member_area,
            item.B21,
            formatNumber2Digit(item.BA11),
            formatNumber2Digit(item.BA12),
            formatNumber2Digit(item.BA13),
            formatNumber(item.sum)])
          B21Sum += parseInt(item.B21);
          BA11Sum += parseFloat(item.BA11);
          BA12Sum += parseFloat(item.BA12);
          BA13Sum += parseFloat(item.BA13);
          allSum += parseFloat(item.sum);
          sumBA1113 = BA11Sum + BA13Sum;
        })
        array.push([
          'รวมทั้งสิ้น',
          B21Sum,
          formatNumber2Digit(BA11Sum),
          formatNumber2Digit(BA12Sum),
          formatNumber2Digit(BA13Sum),
          formatNumber(allSum),
        ]);
        array.push([
          'ค่าน้ำ+ภาษี',
          '',
          '',
          '',
          formatNumber(sumBA1113),
        ]);

        array.push([
          'ค่าบริการ',
          '',
          '',
          '',
          formatNumber(BA12Sum),
        ]);
        var totalPagesExp = '{total_pages_count_string}';
        doc.addFileToVFS('THSarabunNew-normal.ttf', font);
        doc.addFileToVFS('THSarabunNew-bold.ttf', font_bold);
        doc.addFont('THSarabunNew-normal.ttf', 'THSarabunNew', 'normal');
        doc.addFont('THSarabunNew-bold.ttf', 'THSarabunNew', 'bold');
        doc.autoTable({
          showHead: 'everyPage',
          showFoot: 'everyPage',
          styles: {font: 'THSarabunNew', textColor: 0, halign: 'right', fontSize: 14},
          headStyles: {fillColor: [255, 255, 255], textColor: 0, halign: 'right'},
          margin: {left: 8, top: 35, right: 8},
          theme: 'plain',
          head: [
            ['เขต', 'หน่วยที่ใช้', 'ค่าน้ำ', 'ค่าบริการ', 'ภาษี', 'รวม' + this.textTitle]
          ],
          body: array,
          didDrawPage: function (data) {
            // Header
            doc.setFont('THSarabunNew', 'bold');
            doc.setFontSize(16);
            doc.text(org.Org01, doc.internal.pageSize.getWidth() / 2, 10, {align: 'center'});
            doc.text(org.Org03, doc.internal.pageSize.getWidth() / 2, 18, {align: 'center'});
            doc.setFontSize(14);
            doc.text(title + ' ' + billConfig, doc.internal.pageSize.getWidth() / 2, 25, {align: 'center'});
            doc.setFont('THSarabunNew', 'normal');
          },
        });
        const pages = doc.internal.getNumberOfPages();
        const pageSize = doc.internal.pageSize;
        const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
        for (let j = 1; j < pages + 1 ; j++) {
          doc.setPage(j);
          doc.addFileToVFS('THSarabunNew-normal.ttf', font);
          doc.addFileToVFS('THSarabunNew-bold.ttf', font_bold);
          doc.addFont('THSarabunNew-normal.ttf', 'THSarabunNew', 'normal');
          doc.addFont('THSarabunNew-bold.ttf', 'THSarabunNew', 'bold');
          doc.setFont('THSarabunNew', 'normal');
          doc.setFontSize(12);
          doc.text('วันที่พิมพ์ ' + date, 10, pageHeight - 8,)
          doc.text(`หน้าที่พิมพ์ ${j} of ${pages}`, 180, pageHeight - 8, {align: 'center'})
        }
        doc.save(`สรุปยอด` + this.textTitle + `-` + moment().format('DD-MM-YYYY') + '.pdf');
      }
    }
  }
</script>
