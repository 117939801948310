<template>
  <feather-icon icon="PrinterIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" @click="getBookLists()"/>
</template>

<script>
  import 'jspdf-autotable'
  import jsPDF from "jspdf";
  import {font} from "./assets/THSarabunNew-normal";
  import {font_bold} from "./assets/THSarabunNew-bold";
  import moment from "moment";
  import moduleBookList from "@/store/booklist/moduleBookList.js";
  import {formatNumber2Digit, formatNumber} from "./assets/utils";
  export default {
    props: ['billConfig', 'area', 'org', 'status', 'month'],
    components: {
      jsPDF,
    },
    name: "P17PrapaReport",
    data() {
      return {
        thmonth: ['', 'มกราคม', 'กุมภาพันธ์', 'มีนาคม',
          'เมษายน', 'พฤษภาคม', 'มิถุนายน', 'กรกฎาคม', 'สิงหาคม', 'กันยายน',
          'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'],
      }
    },
    methods: {
      maintainDialog() {
        this.$vs.dialog({
          type: 'alert',
          color: 'warning',
          title: `ขอปรับปรุงระบบ`,
          text: 'รายงานจะใช้งานได้หลังวันที่ 1/6/2565',
          acceptText: 'ปิด'
        })
      },
      currentThaiDate() {
        const date = moment().format('DD/MM/YYYY');
        const dateSplit = date.split('/');
        const year = parseInt(dateSplit[2]) + 543;
        return dateSplit[0] + '/' + dateSplit[1] + '/' + year;
      },
      warningDialog() {
        this.$vs.dialog({
          type: 'alert',
          color: 'warning',
          title: `คำเตือน`,
          text: 'ไม่มีรายชื่อในรายงาน ป.17 ค่าน้ำ',
          acceptText: 'ปิด'
        })
      },
      async getBookLists() {
        this.$vs.loading();
        let payload = {};
        payload.B01 = this.billConfig.value;
        if (this.area.value !== 'all') {
          payload.M02 = this.area.value;
        }
        if (this.status !== undefined) {
          payload.B10 = this.status;
        }
        await this.$store.dispatch("bookLists/fetchDebtDataListItems", payload).then((res) => {
          if (res.status === 200) {
            const booklists = res.data;
            this.generatePDF(booklists);
            this.$vs.loading.close();
          } else {
            this.warningDialog();
            this.$vs.loading.close();
          }
        }).catch((error) => {
          console.log(error)
          this.warningDialog();
          this.$vs.loading.close();
        });
      },
      generatePDF(bookLists) {
        const doc = new jsPDF({
          orientation: "portrait",
          unit: "mm",
          format: [279.4, 210]
        });
        const org = this.org;
        const district = org.Org01;
        const address = org.Org03;
        const month = this.month;
        const id_bill = this.billConfig.label;
        const date = this.currentThaiDate()
        let sumUnit = 0;
        let sumPrapa = 0;
        let sumService = 0;
        let sumVat = 0;
        let sum = 0;
        let array = [];
        let sum_payment_footer = 0;
        for (let i = 0; i < bookLists.length; i++) {
          const data = bookLists[i];
          let BA11 = 0;
          let BA12 = 0;
          let BA13 = 0;
          let sumBA = 0;
          if (data.BA11 !== undefined && data.BA11 !== null && data.BA11 !== '' && data.BA11 !== 'NaN') {
            BA11 = parseFloat(data.BA11);
            sumPrapa += parseFloat(data.BA11);
          }
          if (data.BA12 !== undefined && data.BA12 !== null && data.BA12 !== '' && data.BA12 !== 'NaN') {
            BA12 = parseFloat(data.BA12);
            sumService += parseFloat(data.BA12);
          }
          if (data.BA13 !== undefined && data.BA13 !== null && data.BA13 !== '' && data.BA13 !== 'NaN') {
            BA13 = parseFloat(data.BA13);
            sumVat += parseFloat(data.BA13);
          }
          if (data.B21 !== undefined && data.B21 !== null && data.B21 !== '' && data.B21 !== 'NaN') {
            sumUnit += parseInt(data.B21);
          }
          sumBA = BA11 + BA12 + BA13;
          sum += sumBA;
          let day;
          let month;
          let yearTh;
          if(data.B24!==null && data.B24 !==undefined){
            let dateNow = moment(data.B24).format('DD/MM/YYYY');
            if (dateNow === 'Invalid date') {
              let dateArr = data.B24.split(' ');
              let dateArr0 = dateArr[0].split('/');
              yearTh = parseInt(dateArr0[2]) + 543;
              day = parseInt(dateArr0[0]);
              month = this.thmonth[parseInt(dateArr0[1])];
            } else {
              let dateArr0 = dateNow.split('/');
              yearTh = parseInt(dateArr0[2]) + 543;
              day = parseInt(dateArr0[0])
              month = this.thmonth[parseInt(dateArr0[1])];
            }
          }
          let status_bill = 'ค้างจ่าย';
          let sum_payment = 0;
          if (data.B10 === '1') {
            status_bill = 'จ่ายแล้ว';
            sum_payment = sumBA;
          }
          sum_payment_footer += sum_payment;
          let B22 = ''
          if(data.B22 !== '')
            B22 = data.B22
          array.push([
            i + 1,
            data.B03,
            data.member_name,
            data.CB04,
            B22,
            formatNumber2Digit(BA11),
            formatNumber2Digit(BA12),
            formatNumber2Digit(BA13),
            formatNumber(sumBA)
          ]);
        }
        array.push([
          'ผลรวมของ',
          {content: this.area.label, colSpan: 2},
          '',
          '',
          sumPrapa,
          sumService,
          sumVat,
          sum,
          ' ',
          sum_payment_footer
        ]);
        array.push([]);
        array.push([
          'ได้ชำระบิล',
          '',
          '',
          '',
          '',
          bookLists.length + ' ฉบับ',
        ]);
        array.push([
          { content: 'ได้รับเงินค่าน้ำ (ค่าน้ำ+ค่าบริการ+ภาษี)', colSpan: 4 },
          formatNumber(sum),
        ]);

        array.push([
          { content: 'ได้รับเงินรวมจำนวน', colSpan: 4 },
          formatNumber(sum),
        ]);
        var totalPagesExp = '{total_pages_count_string}';
        doc.addFileToVFS('THSarabunNew-normal.ttf', font);
        doc.addFileToVFS('THSarabunNew-bold.ttf', font_bold);
        doc.addFont('THSarabunNew-normal.ttf', 'THSarabunNew', 'normal');
        doc.addFont('THSarabunNew-bold.ttf', 'THSarabunNew', 'bold');
        doc.autoTable({
          showHead: 'everyPage',
          showFoot: 'everyPage',
          styles: {font: 'THSarabunNew', fontSize: 11, textColor: 0},
          margin: {left: 8, top: 35, right: 8},
          theme: 'plain',
          columnStyles: {
            0: {cellWidth: 15},
            1: {cellWidth: 20},
            2: {cellWidth: 50},
            3: {cellWidth: 25},
            4: {cellWidth: 25},
            5: {cellWidth: 15},
            6: {cellWidth: 15},
            7: {cellWidth: 15},
            8: {cellWidth: 15},
          },
          head: [
            [
              'ลำดับที่',
              'รหัส',
              'ชื่อสกุลสมาชิกที่ใช้น้ำ',
              'ประจำเดือน',
              'เลขที่ใบแจ้งหนี้',
              'ค่าน้ำ',
              'ค่าบริการ',
              'ภาษี',
              'รวมเงิน'
            ]
          ],
          body: array,
          didDrawPage: function (data) {
            // Header
            doc.setFont('THSarabunNew', 'bold');
            doc.setFontSize(16);
            doc.text(district, doc.internal.pageSize.getWidth() / 2, 10, {align: 'center'});
            doc.text(address, doc.internal.pageSize.getWidth() / 2, 16, {align: 'center'});
            doc.setFontSize(14);
            doc.text('บัญชีรายตัวลูกหนี้ ประเภทใช้มาตรวัดน้ำ', doc.internal.pageSize.getWidth() / 2, 25, {align: 'center'});
            doc.text('ประจำเดือน ' + month + ' (' + id_bill + ')', doc.internal.pageSize.getWidth() / 2, 30, {align: 'center'});
            doc.setFont('THSarabunNew', 'normal');
            //footer
            var str = 'Page ' + doc.internal.getNumberOfPages();
            if (typeof doc.putTotalPages === 'function') {
              str = str + ' of ' + totalPagesExp
            }
            doc.setFontSize(12);
            var pageSize = doc.internal.pageSize;
            var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
            doc.text('วันที่พิมพ์ ' + date, 8, pageHeight - 8,);
            // doc.text('หน้าที่พิมพ์ ' + str, 288, pageHeight - 8, {align: 'right'});
          },
        });
        if (typeof doc.putTotalPages === 'function') {
          doc.putTotalPages(totalPagesExp);
        }
        doc.save(`ป.17-` + moment().format('DD-MM-YYYY') + '.pdf');
      }
    },
    created() {
      if (!moduleBookList.isRegistered) {
        this.$store.registerModule('bookLists', moduleBookList);
        moduleBookList.isRegistered = true;
      }
    }
  }
</script>
