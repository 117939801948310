<template>
  <feather-icon icon="PrinterIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" @click="getBookLists()"/>
</template>

<script>
  import XLSX from 'xlsx'
  import moment from "moment";
  import {formatNumber} from "./assets/utils";

  export default {
    props: ['billConfig', 'area', 'org', 'status', 'textTitle'],
    components: {},
    name: "RealUserReport",
    data() {
    },
    methods: {
      warningDialog() {
        this.$vs.dialog({
          type: 'alert',
          color: 'warning',
          title: `คำเตือน`,
          text: 'ไม่มีรายชื่อในรายงานสรุป' + this.textTitle,
          acceptText: 'ปิด'
        })
      },
      async getBookLists() {
        this.$vs.loading();
        let payload = {};
        payload.B01 = this.billConfig.value;
        await this.$store.dispatch("bookLists/fetchReportDataListItems", payload).then((res) => {
          if (res.status === 200) {
            const booklists = res.data;
            this.generatePDF(booklists, this.billConfig.label);
            this.$vs.loading.close();
          } else {
            this.warningDialog();
            this.$vs.loading.close();
          }
        }).catch((error) => {
          this.warningDialog();
          this.$vs.loading.close();
        });
      },
      generatePDF(bookLists) {
        let array = [];
        for (let i = 0; i < bookLists.length; i++) {
          const data = bookLists[i]
          array.push({
            'เขต': data.area,
            'หน่วย': formatNumber(data.unit).replace(".00", ""),
            'ค่าน้ำ': formatNumber(data.BA11),
            'ค่าบริการ': formatNumber(data.BA12),
            'ภาษีฯ': formatNumber(data.BA13),
            'ค่าขยะ': formatNumber(data.BA15),
            'รวมยอดตั้งเก็บ': formatNumber(data.total),
            'รวมยอดเก็บได้': formatNumber(data.paidTotal),
            'รวมยอดหนี้ค้างชำระ': formatNumber(data.unPaidTotal)
          })
        }
        let fileName = `สรุปยอดทั้งหมด_`+this.billConfig.label.toString() + '.xlsx';
        let ws = XLSX.utils.json_to_sheet(array);
        let wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, fileName);
        XLSX.writeFile(wb, fileName);
      }
    }
  }
</script>
