<template>
  <feather-icon icon="PrinterIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" @click="getBookLists()"/>
</template>

<script>
  import moduleBookList from "@/store/booklist/moduleBookList.js";
  import jsPDF from "jspdf";
  import 'jspdf-autotable'

  const fs = require('fs');
  import {font} from "./assets/THSarabunNew-normal";
  import {font_bold} from "./assets/THSarabunNew-bold";
  import moment from "moment";
  import {formatNumber2Digit, formatNumber} from "./assets/utils";

  export default {
    props: ['billConfig', 'area', 'org'],
    components: {
      jsPDF,
    },
    name: "P17Monthly",
    data() {
      return {
        villageNo: '4',
        nameVillageNo: 'บ้านเหล่าคราม(04)'
      }
    },
    created() {
      if (!moduleBookList.isRegistered) {
        this.$store.registerModule('bookLists', moduleBookList);
        moduleBookList.isRegistered = true;
      }
    },
    methods: {
      currentThaiDate() {
        const date = moment().format('DD/MM/YYYY');
        const dateSplit = date.split('/');
        const year = parseInt(dateSplit[2]) + 543;
        return dateSplit[0] + '/' + dateSplit[1] + '/' + year;
      },
      warningDialog() {
        this.$vs.dialog({
          type: 'alert',
          color: 'warning',
          title: `คำเตือน`,
          text: 'ไม่มีรายชื่อในรายงาน ป.17 สรุปรายเดือน',
          acceptText: 'ปิด'
        })
      },
      async getBookLists() {
        this.$vs.loading();
        let payload = {
          B01: this.billConfig.value,
        };
        await this.$store.dispatch("bookLists/fetchSummaryDataListItems", payload).then((res) => {
          if (res.status === 200 && res.data.length !== 0) {
            const bookLists = res.data;
            this.generatePDF(bookLists);
            this.$vs.loading.close();
          } else {
            this.warningDialog();
            this.$vs.loading.close();
          }
        }).catch((error) => {
          this.warningDialog();
          this.$vs.loading.close();
        });
      },
      groupBy(xs, key) {
        return xs.reduce(function (rv, x) {
          (rv[x[key]] = rv[x[key]] || []).push(x);
          return rv;
        }, {});
      },
      checkData() {
        if (this.bookLists.length === 0) {
          this.$vs.dialog({
            type: 'alert',
            color: 'danger',
            title: `ผิดพลาด`,
            text: 'ไม่มีรายชื่อผู้ที่ชำระเงินแล้ว',
            acceptText: 'ปิด'
          })
        } else {
          this.generatePDF();
        }
      },
      dateFormat(value) {
        if (value && value !== '-') {
          const datetime = new Date(value);
          datetime.setFullYear(datetime.getFullYear());
          return moment(datetime).tz('Asia/Bangkok').format('DD/MM/YYYY');
        }
        return value;
      },
      generatePDF(bookLists) {
        const doc = new jsPDF({format: [279, 210], unit: 'mm', orientation: 'portrait',});
        const org = this.org;
        const date = this.currentThaiDate()
        let BA11Sum = 0;
        let BA12Sum = 0;
        let BA13Sum = 0;
        let BA14Sum = 0;
        let BA15Sum = 0;
        let allDeptSum = 0;
        let allPaidSum = 0;
        let array = [];
        for (let i = 0; i < bookLists.length; i++) {
          array.push([
            i + 1,
            bookLists[i].area,
            formatNumber2Digit(bookLists[i].BA11),
            formatNumber2Digit(bookLists[i].BA12),
            formatNumber2Digit(bookLists[i].BA13),
            formatNumber2Digit(bookLists[i].BA14),
            formatNumber2Digit(bookLists[i].BA15),
            formatNumber2Digit(bookLists[i].dept),
            formatNumber2Digit(bookLists[i].paid),
          ]);
          BA11Sum += parseFloat(bookLists[i].BA11);
          BA12Sum += parseFloat(bookLists[i].BA12);
          BA13Sum += parseFloat(bookLists[i].BA13);
          BA14Sum += parseFloat(bookLists[i].BA14);
          BA15Sum += parseFloat(bookLists[i].BA15);
          allDeptSum += parseFloat(bookLists[i].dept);
          allPaidSum += parseFloat(bookLists[i].paid);
        }
        array.push([
          ' ',
          'รวมทั้งสิ้น',
          formatNumber2Digit(BA11Sum),
          formatNumber2Digit(BA12Sum),
          formatNumber2Digit(BA13Sum),
          formatNumber2Digit(BA14Sum),
          formatNumber2Digit(BA15Sum),
          formatNumber2Digit(allDeptSum),
          formatNumber2Digit(allPaidSum),
        ]);
        doc.addFileToVFS('THSarabunNew-normal.ttf', font);
        doc.addFileToVFS('THSarabunNew-bold.ttf', font_bold);
        doc.addFont('THSarabunNew-normal.ttf', 'THSarabunNew', 'normal');
        doc.addFont('THSarabunNew-bold.ttf', 'THSarabunNew', 'bold');
        doc.setFont('THSarabunNew', 'bold');
        doc.setFontSize(12);
        doc.autoTable({
          styles: {font: 'THSarabunNew', fontSize: 12},
          margin: {left: 8, top: 40, bottom: 20},
          theme: 'plain',
          head: [['ลำดับที่', 'เขต', 'ค่าน้ำ', 'ค่าธรรมเนียม', 'ภาษี', 'ค่าบำบัดฯ', 'ค่าขยะ', 'ตั้งเก็บ', 'เก็บได้']],
          body: array,
          didDrawPage: function (data) {
            doc.setFontSize(18);
            doc.text(org.Org01, doc.internal.pageSize.getWidth() / 2, 10, {align: 'center'});
            doc.text(org.Org03, doc.internal.pageSize.getWidth() / 2, 18, {align: 'center'});
            doc.setFontSize(16);
            doc.text('รายงานสรุปรายเดือนแยกเขต', doc.internal.pageSize.getWidth() / 2, 28, {align: 'center'});
          },
        });
        const pages = doc.internal.getNumberOfPages();
        const pageSize = doc.internal.pageSize;
        const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
        for (let j = 1; j < pages + 1; j++) {
          doc.setPage(j);
          doc.addFileToVFS('THSarabunNew-normal.ttf', font);
          doc.addFileToVFS('THSarabunNew-bold.ttf', font_bold);
          doc.addFont('THSarabunNew-normal.ttf', 'THSarabunNew', 'normal');
          doc.addFont('THSarabunNew-bold.ttf', 'THSarabunNew', 'bold');
          doc.setFont('THSarabunNew', 'normal');
          doc.setFontSize(12);
          doc.text('วันที่พิมพ์ ' + date, 10, pageHeight - 8,)
          doc.text(`หน้าที่พิมพ์ ${j} of ${pages}`, 180, pageHeight - 8, {align: 'center'})
        }
        doc.save('ป.17สรุปรายเดือน-' + moment().format('DD-MM-YYYY') + '.pdf');
      }
    }
  }
</script>

