<template>
  <feather-icon icon="FileTextIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current ml-5" @click="getBookLists()"/>
</template>

<script>
  import 'jspdf-autotable'
  import jsPDF from "jspdf";
  import moment from "moment";
  import * as XLSX from "xlsx";

  export default {
    props: ['billConfig', 'area', 'org'],
    components: {
      jsPDF,
    },
    name: "RealUserReport",
    data() {
      return {
        title: 'บัญชีรายตัวลูกหนี้ ประเภทค่ากำจัดขยะมูลฝอย',
      }
    },
    methods: {
      currentThaiDate() {
        const date = moment().format('DD/MM/YYYY');
        const dateSplit = date.split('/');
        const year = parseInt(dateSplit[2]) + 543;
        return dateSplit[0] + '/' + dateSplit[1] + '/' + year;
      },
      warningDialog() {
        this.$vs.dialog({
          type: 'alert',
          color: 'warning',
          title: `คำเตือน`,
          text: 'ไม่มีรายชื่อในรายงาน ป.17 ค่ากำจัดขยะมูลฝอย',
          acceptText: 'ปิด'
        })
      },
      async getBookLists() {
        this.$vs.loading();
        let payload;
        if (this.area.value === 'all') {
          payload = {
            B01: this.billConfig.value
          };
        } else {
          payload = {
            M02: this.area.value,
            B01: this.billConfig.value
          };
        }
        await this.$store.dispatch("bookLists/fetchDebtDataListItems", payload).then((res) => {
          if (res.status === 200 && res.data.length !== 0) {
            const bookLists = res.data;
            this.generatePDF(bookLists);
            this.$vs.loading.close();
          } else {
            this.warningDialog();
            this.$vs.loading.close();
          }
        }).catch((error) => {
          this.warningDialog();
          this.$vs.loading.close();
        });
      },
      generatePDF(bookLists) {
        let sumBA15 = 0;
        let sumPaid = 0;
        let array = [];
        let count = 0
        for (let i = 0; i < bookLists.length; i++) {
          let BA15 = 0;
          if (bookLists[i].BA15 !== undefined && bookLists[i].BA15 !== null && bookLists[i].BA15 !== '' && bookLists[i].BA15 !== 'NaN') {
            BA15 = parseFloat(bookLists[i].BA15);
            sumBA15 += parseFloat(bookLists[i].BA15);
          }
          let paid = 0.0
          let paidStatus = 'ค้างจ่าย'
          if (bookLists[i].B10 === '1' && parseFloat(bookLists[i].BA15) > 0) {
            paid = BA15
            paidStatus = 'จ่ายแล้ว';
            sumPaid += parseFloat(bookLists[i].BA15);
          }
          if (BA15 > 0) {
            let paid = 0.0
            let paidStatus = 'ค้างจ่าย'
            if (bookLists[i].B10 === '1') {
              paid = BA15
              paidStatus = 'จ่ายแล้ว';
              sumPaid += parseFloat(bookLists[i].BA15);
            }
            array.push({
              'ลำดับที่': count +1,
              'รหัส': bookLists[i].B03,
              'ชื่อสกุลสมาชิกที่ชำระค่าขยะ': bookLists[i].member_name,
              'เลขที่บิล': bookLists[i].B22,
              'สถานะบิลรอบที่จ่าย': paidStatus,
              'ตั้งเก็บ': parseFloat(BA15).toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2}),
              'ชำระเงิน': parseFloat(paid).toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2}),
            })
            count++
          }

        }
        let fileName = `ป.17ค่าขยะ-` + moment().format('DD-MM-YYYY') + '.xlsx';
        let ws = XLSX.utils.json_to_sheet(array);
        let wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, fileName);
        XLSX.writeFile(wb, fileName);
      }
    }
  }
</script>
