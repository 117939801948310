<template>
  <feather-icon icon="PrinterIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" @click="generatePDF()"/>
</template>

<script>
  import jsPDF from "jspdf";
  import 'jspdf-autotable'

  const fs = require('fs');
  import {font} from "./assets/THSarabunNew-normal";
  import {font_bold} from "./assets/THSarabunNew-bold";
  import moment from "moment";

  export default {
    components: {
      jsPDF,
    },
    name: "RealUserReport",
    data() {
      return {
        district: 'องค์การบริหารส่วนตำบลจำปาโมง',
        address: 'ต.จำปาโมง อ.บ้านผือ จ.อุดรธานี้',
        title: 'บัญชีประจำตัวผ฿้เก็บเงิน ป.32 ประจำวัน สรุปยอดทั้งหมด',
        month: 'ตุลาคม-61',
        id_bill: '(62-001)',
        date: '20/01/2020',
      }
    },
    methods: {
      currentThaiDate() {
        const date = moment().format('DD/MM/YYYY');
        const dateSplit = date.split('/');
        const year = parseInt(dateSplit[2]) + 543;
        return dateSplit[0] + '/' + dateSplit[1] + '/' + year;
      },
      generatePDF() {
        const doc = new jsPDF({format: [279, 210], unit:'mm', orientation: 'portrait',});
        const district = this.district
        const address = this.address
        const title = this.title
        const month = this.month
        const id_bill = this.id_bill
        const date = this.currentThaiDate()
        let array = [];
        array.push([' ', '37330.00 ', '4990.00', ' ', ' ', ' ', '42320.00']);
        for (let i = 0; i < 40; i++) {
          array.push(['06/11/2017', '32830.00 ', '4775.00', ' ', ' ', ' ', '37688.00']);
        }
        var totalPagesExp = '{total_pages_count_string}';
        doc.addFileToVFS('THSarabunNew-normal.ttf', font);
        doc.addFileToVFS('THSarabunNew-bold.ttf', font_bold);
        doc.addFont('THSarabunNew-normal.ttf', 'THSarabunNew', 'normal');
        doc.addFont('THSarabunNew-bold.ttf', 'THSarabunNew', 'bold');
        doc.autoTable({
          showHead: 'everyPage',
          showFoot: 'everyPage',
          styles: {font: 'THSarabunNew', textColor: 0},
          fontSize: 14,
          margin: {left: 8, top: 35, right: 8},
          theme: 'plain',
          head: [
            ['วันที่ชำระ', 'ค่าน้ำ', 'ค่าธรรมเนียม', 'ภาษี', 'ค่าบำบัดฯ', 'ค่าขยะ', 'รวมทั้งสิ้น']
          ],
          body: array,
          didDrawPage: function (data) {
            // Header
            doc.setFont('THSarabunNew', 'bold');
            doc.setFontSize(16);
            doc.text(district, doc.internal.pageSize.getWidth() / 2, 10, {align: 'center'});
            doc.text(address, doc.internal.pageSize.getWidth() / 2, 16, {align: 'center'});
            doc.setFontSize(14);
            doc.text(title, doc.internal.pageSize.getWidth() / 2, 25, {align: 'center'});
            doc.setFont('THSarabunNew', 'normal');
            //footer
            var str = 'Page ' + doc.internal.getNumberOfPages();
            // Total page number plugin only available in jspdf v1.0+
            if (typeof doc.putTotalPages === 'function') {
              str = str + ' of ' + totalPagesExp
            }
            doc.setFontSize(12);
            // jsPDF 1.4+ uses getWidth, <1.4 uses .width
            var pageSize = doc.internal.pageSize;
            var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
            doc.text('วันที่พิมพ์ ' + date, 10, pageHeight - 8,)
            doc.text('หน้าที่พิมพ์ ' + str, 200, pageHeight - 8, {align: 'right'});
          },
        });
        doc.setProperties({
          title: `report${new Date().toLocaleString()}`
        });
        doc.output("dataurlnewwindow");
      }
    }
  }
</script>
